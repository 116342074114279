import { useMutation, useQueryClient } from 'react-query';
import queries from '../queries';

const useCompanyEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, companyName, countryId, currency, language, industry }) => {
      return queries.companies.edit.query(id, companyName?.trim(), countryId, currency, language, industry);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.companies.list.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useCompanyCompressorEdit = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }) => {
      return queries.companies.editCompanyCompressor.query(id, data);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.compressors.get.key);
        queryClient.invalidateQueries(queries.companies.list.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useCompanyDelete = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }) => {
      return queries.companies.delete.query(id);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.companies.list.key);
        queryClient.invalidateQueries([queries.companies.stats.key, variables.companyId]);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useCompanyCreate = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ companyName, companyId, countryId, currency, language, industry }) => {
      return queries.companies.createByAdmin.query(companyName?.trim(), companyId, countryId, currency, language, industry);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.companies.list.key);
        queryClient.invalidateQueries(queries.myQuota.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useSetEssentialSettings = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ companyId, active, inherited }) => {
      return queries.companies.setEssential.query(companyId, active, inherited);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries([queries.companies.users.key, variables.companyId]);
        queryClient.invalidateQueries(queries.companies.stats.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

const useHubspotSyncSettings = (callbacks = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ companyId, active, inherited }) => {
      return queries.companies.updateHubspotSyncSettings.query(companyId, active, inherited);
    },
    {
      ...callbacks,
      onSuccess: ({ data }, variables) => {
        queryClient.invalidateQueries(queries.companies.stats.key);
        if (typeof callbacks.onSuccess === 'function') callbacks.onSuccess(data, variables);
      },
    }
  );
};

export default {
  useCompanyEdit,
  useCompanyCompressorEdit,
  useCompanyDelete,
  useCompanyCreate,
  useSetEssentialSettings,
  useHubspotSyncSettings,
};
