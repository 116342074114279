import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import useTheme from './hooks/useTheme';
import routes, { renderRoutes } from 'src/routes';
import GlobalStyles from 'src/components/GlobalStyles';

import QueryProvider from 'src/contexts/QueryContext';
import { AuthProvider } from 'src/contexts/AuthContext';
import { ValidationProvider } from 'src/contexts/ValidationContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { UnitProvider } from './contexts/UnitContext';
import { ReleaseNotesProvider } from './contexts/ReleaseNotesContext';
import LanguageProvider from './contexts/LanguageContext';

import ErrorBoundary from './components/ErrorBoundary';
import Version from './components/Version';

const history = createBrowserHistory();

function App() {
  const { theme } = useTheme();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />

        <LanguageProvider>
          <Router history={history}>
            <ReleaseNotesProvider>
              <SnackbarProvider>
                <AuthProvider>
                  <QueryProvider>
                    <ValidationProvider>
                      <UnitProvider>{renderRoutes(routes)}</UnitProvider>
                    </ValidationProvider>
                  </QueryProvider>

                  {/* render version footer */}
                  <Version />
                </AuthProvider>
              </SnackbarProvider>
            </ReleaseNotesProvider>
          </Router>
        </LanguageProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
