import { useMemo } from 'react';
import { createTheme, colors } from '@material-ui/core';
import shadows from '../theme/shadows';
import typography from '../theme/typography';

import ProsarisLogoLogin from 'src/assets/ProsarisLogo.svg';
import ProsarisLogoTopbar from 'src/assets/prosaris_white.png';
import ProsarisLogoReport from 'src/assets/ProsarisLogoBlack.svg';
import ProsarisFavicon from 'src/assets/favicon.svg';

import SonusLogoLogin from 'src/assets/sonus_stacked_logo.svg';
import SonusLogoTopbar from 'src/assets/sonus_logo.svg';
import SonusFavicon from 'src/assets/sonus_favicon.png';

const urlMappings = {
  sonus: ['sonus.prosaris.ca', 'sonus-staging-us.prosaris.ca'],
};

const brandConfig = {
  default: {
    theme: {
      PRIMARY_COLOR: '#5e7ad2',
      PRIMARY_DARK: '#4B68C1',
      SECONDARY_COLOR: '#53565a',
      PRIMARY_TEXT: '#53565A',
      DARK_BACKGROUND: '#333740',
      SUCCESS: '#26b562',
      BRANDING_MAIN: '#5e7ad2',
      BRANDING_SHADE: '#4B68C1',
    },
    logo: {
      login: {
        src: ProsarisLogoLogin,
        height: 190,
        alt: 'Prosaris Logo',
      },
      topbar: {
        src: ProsarisLogoTopbar,
        width: 140,
        height: 20,
        alt: 'Prosaris Logo',
      },
      report: {
        src: ProsarisLogoReport,
        height: 15,
        alt: 'Prosaris Logo',
      },
      favicon: {
        src: ProsarisFavicon,
      },
    },
    title: 'PROSARIS Dashboard',
    bannersEnabled: true,
  },
  sonus: {
    theme: {
      PRIMARY_COLOR: '#4a90e2',
      PRIMARY_DARK: '#3a78b8',
      SECONDARY_COLOR: '#53565a',
      PRIMARY_TEXT: '#53565A',
      DARK_BACKGROUND: '#2c3e50',
      SUCCESS: '#27ae60',
      BRANDING_MAIN: '#1D1F22',
      BRANDING_SHADE: '#191A1D',
    },
    logo: {
      login: {
        src: SonusLogoLogin,
        height: 150,
        alt: 'SONUS Logo',
      },
      topbar: {
        src: SonusLogoTopbar,
        height: 30,
        alt: 'SONUS Logo',
      },
      report: {
        src: SonusLogoTopbar,
        height: 25,
        alt: 'SONUS Logo',
      },
      favicon: {
        src: SonusFavicon,
      },
    },
    title: 'SONUS Dashboard',
    bannersEnabled: false,
  },
};

const createCustomTheme = (themeConstants) => {
  const { PRIMARY_COLOR, PRIMARY_DARK, SECONDARY_COLOR, PRIMARY_TEXT, DARK_BACKGROUND, SUCCESS, BRANDING_MAIN, BRANDING_SHADE } =
    themeConstants;

  return createTheme({
    palette: {
      background: {
        default: '#F3F6F8',
        paper: colors.common.white,
        dark: DARK_BACKGROUND,
      },
      branding: {
        main: BRANDING_MAIN,
        shade: BRANDING_SHADE,
      },
      primary: {
        highlight: '#EFF3FF',
        main: PRIMARY_COLOR,
        dark: PRIMARY_DARK,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
      text: {
        primary: PRIMARY_TEXT,
        secondary: '#7E7E7E',
      },
      success: {
        main: SUCCESS,
      },
      common: {
        white: colors.common.white,
        black: colors.common.black,
      },
      disabled: {
        warning: '#ffbdad',
      },
    },
    overrides: {
      MuiFormControl: {
        marginDense: {
          marginTop: 20,
        },
      },
      MuiButton: {
        root: {
          borderRadius: 0,
          minWidth: '170px',
          height: '40px',
        },
        sizeSmall: {
          minWidth: '100px',
          height: '28px',
        },
        contained: {
          backgroundColor: DARK_BACKGROUND,
          color: colors.common.white,
          '&:hover': {
            backgroundColor: colors.grey[600],
          },
        },
        outlined: {
          backgroundColor: colors.common.white,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: '2px',
          backgroundColor: colors.common.white,
        },
      },
      MuiTypography: {
        h2: {
          marginBottom: '1.5rem',
        },
      },
      MuiTableContainer: {
        root: {
          backgroundColor: colors.common.white,
          boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
        },
      },
      MuiTableRow: {
        head: {
          backgroundColor: PRIMARY_COLOR,
        },
      },
      MuiTableCell: {
        root: {
          flexShrink: 0,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        head: {
          backgroundColor: PRIMARY_COLOR,
          color: colors.common.white,
          fontSize: 12,
          lineHeight: '1rem',
        },
        stickyHeader: {
          backgroundColor: PRIMARY_COLOR,
          color: colors.common.white,
          fontSize: 12,
          '&:hover': {
            color: colors.common.white,
          },
        },
      },
      MuiTableSortLabel: {
        root: {
          '&:hover': {
            color: colors.common.white,
          },
          '&:focus': {
            color: colors.common.white,
          },
          '&$active': {
            color: colors.common.white,
          },
        },
        icon: {
          color: `${colors.common.white} !important`,
        },
        active: {},
      },
      MuiInputLabel: {
        shrink: {
          backgroundColor: colors.common.white,
        },
      },
      MuiChip: {
        root: {
          backgroundColor: DARK_BACKGROUND,
          color: colors.common.white,
          '&:active': {
            backgroundColor: DARK_BACKGROUND,
          },
        },
        deletable: {
          '&:focus': {
            backgroundColor: DARK_BACKGROUND,
          },
        },
        deleteIcon: {
          color: colors.common.white,
          '&:hover': {
            color: colors.grey[500],
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 15,
        },
      },
    },
    shadows,
    typography,
  });
};

const getConfigKeyForHostname = (hostname) => {
  for (const [configKey, urls] of Object.entries(urlMappings)) {
    if (urls.includes(hostname)) {
      return configKey;
    }
  }
  return 'default';
};

const useTheme = () => {
  const hostname = useMemo(() => window.location.hostname, []);
  const configKey = useMemo(() => getConfigKeyForHostname(hostname), [hostname]);
  const config = useMemo(() => brandConfig[configKey], [configKey]);

  const theme = useMemo(() => createCustomTheme(config.theme), [config]);

  useMemo(() => {
    const favicon = document.querySelector('link[rel="shortcut icon"]');
    if (favicon) {
      favicon.href = config.logo.favicon.src;
    }
    document.title = config.title;
  }, [config]);

  return { theme, logo: config.logo, bannersEnabled: config.bannersEnabled };
};

export default useTheme;
