export default {
  translation: {
    commonUsedWords: {
      login: 'Logg inn',
      email: 'E-postadresse',
      password: 'Passord',
      emailIsRequired: 'E-post er nødvendig',
      mustBeAValidEmail: 'Må være en gyldig e-post',
      deviceSerialNumber: 'Enhets serienummer',
      next: 'Neste',
      success: 'Suksess',
      submit: 'Send inn',
      firstName: 'Fornavn',
      firstNameIsRequired: 'Fornavn er nødvendig',
      lastName: 'Etternavn',
      lastNameIsRequired: 'Etternavn er nødvendig',
      save: 'Lagre',
      delete: 'Slett',
      undoChanges: 'Angre endringer',
      cancel: 'Avbryt',
      loading: 'Laster...',
      yes: 'Ja',
      no: 'Nei',
      clear: 'Tøm',
      confirm: 'Bekrefte',
      noOption: 'Ingen alternativer',
      openText: 'Åpne',
      edit: 'Rediger',
      logout: 'Logg ut',
      latitude: 'Breddegrad',
      longitude: 'Lengdegrad',
      ok: 'Ok',
      okay: 'Ok',
      done: 'Ferdig',
      comingSoon: 'Kommer snart...',
      reset: 'Tilbakestill',
      attention: 'Oppmerksomhet',
      note: 'Merk',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Obligatoriske felt mangler',
        userNotFound: 'Bruker ikke funnet',
        missingRefreshToken: 'Oppdateringstoken mangler',
        cannotRefreshToken: 'Kan ikke oppdatere token',
      },
      helpers: {
        error: 'Feil',
        invalidHTTPMethod: 'Ugyldig HTTP-metode',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'Bekreft e-postadresse',
        confirmEmailIsRequired: 'Bekreftet e-post er nødvendig',
        emailNotMatched: 'E-postoppføringer stemmer ikke overens',
      },
      confirmPassword: {
        confirmPassword: 'Bekreft passord',
        confirmPasswordIsRequired: 'Bekreftet passord er nødvendig',
        newPasswordIsRequired: 'Nytt passord er nødvendig',
        showPassword: 'Vis passord',
        passwordLength: 'Passord må være minst 6 tegn',
        passwordContainSpaces: 'Passord må ikke inneholde mellomrom',
        passwordNotMatch: 'Passordoppføringer stemmer ikke overens',
        newPassword: 'Nytt passord',
      },
      confirmModal: {
        areYouSure: 'Er du sikker?',
      },
      appliedFilters: {
        between: 'Mellom {{firstValue}} og {{secondValue}}',
        bottom: 'Bunn {{primaryValue}}%',
        top: 'Topp {{primaryValue}}%',
        activeFilters: 'Aktive filtre',
        active: 'aktiv',
        closed: 'lukket',
      },
      editTable: {
        editTable: 'Rediger tabell',
        selectDeselect: 'Velg/Fjern valg av tabellverdier',
        resetToDefault: 'Tilbakestill til standard',
      },
      errorBoundary: {
        oops: 'Oi! Noe uventet skjedde.',
        tryAgain: 'Vennligst prøv igjen! Hvis problemet vedvarer, vennligst kontakt din administrator.',
        refresh: 'Oppdater',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Beklager, ingen resultater funnet. Vennligst prøv igjen.',
      },
      permissionGuard: {
        invalidPermission: 'Ugyldig tillatelsesstruktur, 3 elementer forventes i arrayen',
      },
      preview: {
        findInView: 'Finn i visning',
        showDetails: 'Vis detaljer',
        closeEsc: 'Lukk (Esc)',
        prevLeftArrow: 'Forrige (Venstre pil)',
        nextRightArrow: 'Neste (Høyre pil)',
        proceedWithoutSaving: 'Naviger uten å lagre',
        continueEditing: 'Fortsett å redigere',
        unsavedChanges: {
          partTwo: 'Du har ',
          partThree: 'IKKE LAGREDE ENDringer',
          partFour: '. Navigering uten å lagre vil forkaste alle endringer. Er du sikker?',
        },
      },
      printRecord: {
        title: 'Oppføringer',
        date: 'Dato:',
        location: 'Plassering',
        leakDetails: 'Detaljer',
        leakParameters: 'Parametre',
        energyLoss: 'Energitap',
        leakSourceEquipment: 'Kildeutstyr',
        notes: 'Merk',
        leakEventStatus: 'Status',
        requiredAction: 'Nødvendig handling',
        leakSourceImage: 'Kildebilde',
        smartLeakTag: 'Smart tag',
        lossPerYear: 'Tap ({{currency}}/år)',
        costOfEnergy: 'Energikostnad ({{currency}}/kWh)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'Oppgaven er slettet',
          deleteAssignmentError: 'Oppgaven kan ikke slettes. Vennligst prøv igjen senere',
          currentlyAssigned: 'Gjeldende tildelte handling',
          repairedBy: 'Reparert av',
          text: 'Du er i ferd med å slette denne oppgaven. Denne handlingen kan ikke angres',
        },
        actionSaveDate: 'Sist lagret',
      },
      visibleColumnsPopper: {
        tableValues: 'Velg / opphev valg av tabellverdier',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Lik',
        LESS_THAN: 'Mindre enn',
        GREATER_THAN: 'Større enn',
        LESS_THAN_OR_EQUAL_TO: 'Mindre enn eller lik',
        GREATER_THAN_OR_EQUAL_TO: 'Større enn eller lik',
        BETWEEN_A_AND_Y: 'Mellom',
        TOP_PERCENT: 'Topp "x"%',
        BOTTOM_PERCENT: 'Bunn "x"%',
      },
      recordFields: {
        CREATED_AT: 'Opprettet dato',
        RECORD_ID: 'Registrerings-ID',
        DOCUMENT_ID: 'Registrerings-ID',
        DOLLARS_PER_YEAR: 'Tap',
        GAS_COMPONENT: 'Gass/Komponent',
        LEAK_TYPE: 'Type profil',
        DATE_TIME: 'Dato/Tid',
        DB_PEAK: 'DB Topp',
        COMPANY: 'Selskap',
        NOTES: 'Notater',
        USER: 'Bruker',
        EQUIPMENT_TAG: 'Utstyrsmerke/Komponent',
        FLOW: 'Strøm (m³/h)',
        EQUIPMENT: 'Enhet/Utrustning',
        ROUTE: 'Rute',
        ROUTE_LOCATION: 'Ruteplassering',
        PROJECT_SURVEY: 'Audit/Undersøkelsesreferanse',
        OUTAGE_STATUS: 'Utrykning/avstenging kreves?',
        FACILITY: 'Anlegg/Område',
        ACTION_STATUS: 'Handlingsstatus',
        SERVICE_TYPE: 'Service/Type',
        DB_ACTIVE: 'DB Aktiv',
        REFERENCE_ID: 'Prosjekt #/Undersøkelses-ID',
        AMBIENT_TEMPERATURE: 'Romtemperatur (°C)',
        LEAK_PRESSURE: 'Trykk (kPag)',
        LEAK_TEMPERATURE: 'Gass Temperatur (°C)',
        LEAK_CATEGORY: 'Kategori',
        DISTANCE: 'Avstand (m)',
        GAS_COST: 'Gaskostnad ($/m³)',
        COMPRESSOR_FLOW: 'm³/h per BHP',
        DEVICE_ID: 'Enhets-ID',
        COMPRESSOR_BHP: 'Kompressor BHP',
        COMPRESSOR_TYPE: 'Kompressor Type',
        MOTOR_EFFICIENCY: 'Motor Effektivitet (%)',
        ENERGY_COST: 'Energi Kostnad ($/kWh)',
        HOURS: 'Timer Drift/År',
        LOCATION: 'Plassering',
        LAST_MODIFIED: 'Siste Lagret Dato',
        POWER_LOAD: '% Full Last',
        CLIENT: 'Kunde',
        SERVICE: 'Service',
        CAPACITY: '% Kapasitet',
        EQUIPMENT_TYPE: 'Utstyr/Komponent Type',
        LEAK_TAG: 'Smart Tag #/Arbeidsreferanse',
        REPAIRED_BY: 'Reparert Av',
        ALTITUDE: 'Høyde (m)',
        REQUIRED_ACTION: 'Påkrevd Handling',
        FREQUENCY_LOW_BOUND: 'Frekvens (Lav)',
        FREQUENCY_HIGH_BOUND: 'Frekvens (Høy)',
        SENSITIVITY: 'Sensitivitet',
        KWH_PER_YEAR: 'kWh/år',
        ASSIGNED_BY: 'Tilordnet Av',
        ASSIGNED_TO: 'Tilordnet Til',
        ASSIGNED_DATE: 'Tilordningsdato',
        LOSS_PER_YEAR: 'Tap (per år)',
        ASSIGNED: 'Tilordnet',
        CREATOR_USER_ID: 'Bruker-ID',
        CURRENCY: 'Valuta',
        COMPLETED_ON: 'Ferdig Dato',
        IMAGES: 'Kildebilder',
        ACTION_STATUS_MODIFIED: 'Handlingsstatus endret',
        TYPE: 'Type',
      },
      electricalRecordFields: {
        REGION: 'Region',
        LOCATION_TYPE: 'Plasseringstype',
        VOLTAGE: 'Spenning',
        PHASE: 'Fase',
        FREQUENCY: 'Frekvens',
        ELECTRICAL_EQUIPMENT_TAG: 'Utstyr/Komponent',
        ELECTRICAL_EQUIPMENT_TYPE: 'Utstyr/Komponenttype',
        FAULT_TYPE: 'Feiltyper',
        ELECTRICAL_NOTES: 'Notater',
      },
      IMPERIAL_LABELS: {
        FLOW: 'Strømning (cfm)',
        AMBIENT_TEMPERATURE: 'Omgivelsestemperatur (°F)',
        LEAK_PRESSURE: 'Trykk (psi)',
        LEAK_TEMPERATURE: 'Gastemperatur (°F)',
        DISTANCE: 'Avstand (ft)',
        GAS_COST: 'Gasskostnad ($/ft³)',
        COMPRESSOR_FLOW: 'cfm per BHP',
        ALTITUDE: 'Høyde (ft)',
      },
      userFields: {
        EMAIL: 'E-post',
        ROLE: 'Rolle',
        DEVICE_ID: 'Enhets-ID',
        SUBSCRIPTION_RENEWAL_DATE: 'Abonnementsfornyelsesdato',
        DATE_TIME: 'Opprettet',
        ACTIVE: 'Status',
        SOFTWARE_VERSION: 'Programvareversjon',
        FIRMWARE_VERSION: 'Fastvareversjon',
        FIRST_NAME: 'Fornavn',
        LAST_NAME: 'Etternavn',
        COMPANY_SLUG: 'Selskap',
        COMPANY_NAME: 'Selskap',
      },
      auditFields: {
        documentId: 'Post-ID-nummer',
        companyName: 'Bedriftnavn',
        auditorName: 'Revisornavn',
        auditorEmail: 'Revisorens e-post',
        createdAt: 'Opprettet',
        updatedAt: 'Oppdatert',
        comment: 'Kommentarer',
        isLocked: 'Revisjonslås',
      },
      deviceFields: {
        DEVICE_ID: 'Enhets-ID',
        ACTIVE: 'Status',
        REGISTERED_ON: 'Aktivert den',
        EVENT_COUNT: 'Poster',
        UNIQUE_COMPANIES: 'Selskaper',
        LAST_USED_AT: 'Sist brukt den',
        LAST_USED_BY_NAME: 'Sist brukte navn',
        LAST_USED_BY_EMAIL: 'Sist brukte e-post',
        LOCATION: 'Sist brukte plassering',
        FIRMWARE_VERSION: 'Fastvareversjon',
      },
      statsFields: {
        labels: {
          TIMELINE: 'Tidslinje',
          CRITERIA: 'Kriterier',
        },
        timeline: {
          LAST_3_MONTHS: 'Siste 3 måneder',
          LAST_6_MONTHS: 'Siste 6 måneder',
          LAST_12_MONTHS: 'Siste 12 måneder',
          YEAR_TO_DATE: 'År til dags dato',
          ALL_TIME: 'Hele tiden',
        },
        criteria: {
          LOSS: 'Tap',
          FLOW: 'Flyt',
          KWH: 'kWh',
        },
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: '# Funne lekkasjer',
          NO_OF_LEAKS_REPAIRED: '# Reparer lekkasjer',
          PERCENT_LEAKS_REPAIRED: '% Reparer lekkasjer',
          VOL_FLOW_FOUND: 'Volumstrøm funnet (m³/h)',
          VOL_FLOW_REPAIRED: 'Volumstrøm reparert (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '% Vol. Strøm reparert',
          VALUE_FOUND: 'Verdi funnet',
          VALUE_REPAIRED: 'Verdi reparert',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energisparemulighet (funnet) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Energisparemulighet % (funnet vs beregnet eller målt)',
          ENERGY_SAVING_REALIZED_FIXED: 'Realiserte energisparing (reparert) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: '% Realisert energisparing (reparert vs beregnet eller målt)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Systemkontrolltrykk redusert (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Volumstrøm funnet (cfm)',
          VOL_FLOW_REPAIRED: 'Volumstrøm reparert (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Energisparemulighet (funnet) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: 'Realiserte energisparing (reparert) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Systemkontrolltrykk redusert (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Ytelsesindikator',
          ENPI_VALUE: 'EnPI-verdi',
          ENB_VALUE: 'EnB-verdi',
          SUCCESS_THRESHOLD: 'Suksessgrense (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Tildelt til',
        assignedBy: 'Tildelt av',
        assignedDate: 'Tildelingsdato',
        requestReassign: 'Be om ny tildeling',
        actionStatus: 'Handlingsstatus',
        partsNeeded: 'Del påkrevd',
        productionOutage: 'Nedstenging forespurt',
        timeToRepair: 'Reparasjonstid',
        repairCost: 'Reparasjonskostnad',
        closeOutRef: 'Lukking ref',
        'assignmentRecord.documentId': 'Post-ID',
        assignmentId: 'Tildelings-ID',
        owner: 'Eier',
        companyName: 'Selskap',
        assignmentActive: 'Tildelingsstatus',
        'assignmentRecord.leakStatus.building': 'Enhet/Utstyr',
        'assignmentRecord.leakStatus.facility': 'Anlegg',
        'assignmentRecord.leakStatus.routeName': 'Rute',
        'assignmentRecord.leakStatus.leakTag': 'Smart etikett #/Arbeidsreferanse #',
        'assignmentRecord.currency': 'Valuta',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Tiltak tatt',
        CLOSE_OUT_REF: 'Tiltak avslutningsreferanse #',
        PARTS_NEEDED: 'Ny del/komponent kreves',
        PRODUCTION_OUTAGE: 'Produksjonen ble avbrutt',
        REPAIR_COST: 'Kostnad ($)',
        REQUEST_REASSIGN: 'Be om omfordeling',
        ACTION_STATUS: 'Handlingsstatus',
        TIME_TO_REPAIR: 'Tid tatt for reparasjon (minutter)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Flyt etter lekkasjekategori',
          LOSS_BY_LEAK: 'Tap etter lekkasjekategori',
          TAGS_BY_LEAK: 'Merker etter lekkasjekategori',
          AVG_FLOW_BY_LEAK: 'Gjennomsnittlig flyt etter lekkasjekategori',
          SAVING_OPT_BY_REPAIR: 'Muligheter og besparelser etter reparasjonstid',
          FLOW_REPAIR_TIME: 'Flyt etter reparasjonstid',
          LOSS_REPAIR_TIME: 'Tap etter reparasjonstid',
          FLOW_REPAIR_PROG: 'Flyt etter reparasjonsfremdrift',
          TOTAL_REPAIR_PROG: 'Tap etter reparasjonsfremdrift',
          FLOW_BY_EQUIP_COMP: 'Flyt etter utstyr/komponent',
          LOSS_BY_EQUIP_COMP: 'Tap etter utstyr/komponent',
          TAGS_BY_EQUIP_COMP: 'Merker etter utstyr/komponent',
        },
        leakCategoryLabels: {
          LOW: 'Lav',
          MEDIUM: 'Middels',
          HIGH: 'Høy',
        },
        actionStatus: {
          OPEN: 'Åpne',
          COMPLETE: 'Fullfør',
        },
        equipmentTypes: {
          CONNECTOR: 'Kobling',
          CONTROL_VALVE: 'Kontrollventil',
          HOSE: 'Slange',
          TUBE_FITTING: 'Rørkobling',
          METER: 'Måler',
          OPEN_ENDED_LINE: 'Åpen linje',
          PRESSURE_RELIEF_VALVE: 'Trykkavlastningsventil',
          PUMP_SEAL: 'Pumpetetning',
          REGULATOR: 'Regulator',
          VALVE: 'Ventil',
          LEVEL_CONTROLLER: 'Nivåkontroller',
          POSITIONER: 'Positioonsgiver',
          PRESSURE_CONTROLLER: 'Trykkontroller',
          TRANSDUCER: 'Transduser',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Generisk pneumatisk instrument',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Generisk stempelkompressor',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Generisk sentrifugalkompressor',
        },
        mainGasValues: {
          AIR: 'Luft',
          METHANE: 'Metan (CH4)',
          CARBON_DIOXIDE: 'Karbondioksid (CO2)',
          HYDROGEN: 'Hydrogen (H2)',
          HELIUM: 'Helium (He)',
          NITROGEN: 'Nitrogen (N2)',
          REFRIGERANT: 'Kjølemiddel (HFK)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oksygen (O)',
          WATER: 'Vann (Damp)',
        },
        leakTypeValues: {
          DEFAULT: 'Ingen valgt',
          HOLE: 'Hull',
          CRACK: 'Sprekk',
          FITTING: 'Kobling',
          FLANGE: 'Flens',
          'GLAND/SEAL': 'Pakning/Tetning',
          THREAD: 'Gjenge',
          'TUBE/HOSE': 'Rør/Slange',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Komprimert luft',
          VACUUM: 'Vakuum',
          STEAM: 'Damp',
          PROCESS_GAS: 'Prosessgass',
          LIQUID: 'Væske',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Roterende skrue',
          RECIPROCATING: 'Stempel',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Sentrifugal',
        },
        regionValues: {
          NORTH_AMERICA: 'Nord-Amerika',
          OUTSIDE_NORTH_AMERICA: 'Utenfor Nord-Amerika',
        },
        locationTypeValues: {
          RESIDENTIAL: 'Bofasiliteter',
          COMMERCIAL: 'Kommersielle anlegg',
          INDUSTRIAL: 'Industrielle anlegg',
          MEDIUM_VOLTAGE: 'Mellomspenning',
          HIGH_VOLTAGE: 'Høyspenning',
        },
        phaseValues: {
          SINGLE_PHASE: 'En-fase',
          THREE_PHASE: 'Trefase',
        },
        faultValues: {
          SELECT_FAULT_TYPE: 'Velg feiltype',
          KNICKS_OR_VOIDS_IN_INSULATION: 'Kvisting eller tomrom i isolasjon',
          CORONA: 'Corona',
          TREEING: 'Trefelling',
          TRACKING: 'Trafikking',
          ARCING: 'Bue',
          'DIRTY_POWER/POWER_DISTORTION': 'Skitten strøm/strømforvrengning',
          MECHANICAL_LOOSENESS: 'Mekanisk løshet',
          DELAMINATION_OF_WINDINGS: 'Delaminering av viklinger',
          LOOSE_PANEL: 'Løst panel',
          VIBRATING_NAMEPLATE: 'Vibrerende navneskilt',
          ANTAGONISTIC_ULTRASOUND: 'Antagonistisk ultralyd',
          OTHER: 'Annet',
        },
        electricEquipmentTypeValues: {
          MCC: 'MCC (Motorstyringsutstyr)',
          'TRANSFORMER,_DRY': 'Transformator, tørr',
          'TRANSFORMER,_OIL': 'Transformator, olje',
          SWITCH_GEAR: 'Bryteutstyr',
          PANEL: 'Panel',
          BUSDUCT: 'Busduct',
          CABLE: 'Kabel',
          CONNECTOR: 'Kobling',
          BREAKER: 'Bryter',
          BUSHING: 'Isolator',
          TRANSMISSION_LINE: 'Overføringslinje',
          'TRANSFORMER,_POLE': 'Transformator, stolpe',
          BATTERY: 'Batteri',
          BATTERY_BANK: 'Batteribank',
          TERMINALS: 'Terminaler',
          'TRANSFORMER,_STEP_UP': 'Transformator, opptrapping',
          'TRANSFORMER,_STEP_DOWN': 'Transformator, nedtrapping',
          DISTRIBUTION_PANEL: 'Fordelingspanel',
          BREAKER_PANEL: 'Bryterpanel',
          RELAYS: 'Relé',
          CAPACITORS: 'Kondensatorer',
          REACTORS: 'Reaktorer',
          UPS: 'UPS',
          GROUNDING: 'Jording',
          POWER_METERS: 'Strømmålere',
          CURRENT_TRANSFORMERS: 'Strømtransformatorer',
          POTENTIAL_TRANSFORMERS: 'Spenningstransformatorer',
          SURGE_PROTECTION: 'Overspenningsvern',
          CABLE_TRAYS: 'Kabelføringer',
          EMERGENCY_LIGHTING: 'Nødbelysning',
          CONTACTORS: 'Kontaktorer',
          INVERTERS: 'Omformere',
          SOLAR_PANELS: 'Solpaneler',
          BATTERY_STORAGE_SYSTEMS: 'Batterilagringssystemer',
        },
        recordTypes: {
          leak: 'Lekkasjer',
          electrical: 'Elektrisk',
        },
        labels: {
          LOW: 'Lav',
          MEDIUM: 'Middels',
          HIGH: 'Høy',
          EQUIPMENT: 'Utstyr',
          SAVINGS: 'Besparelser',
          OPPORTUNITY: 'Mulighet',
          LOSS: 'Tap',
          REPAIRED: 'Reparert',
          UNREPAIRED: 'Ikke reparert',
          LESS_15_DAYS: 'Mindre enn 15 dager',
          DAYS_16_TO_30: '15 til 30 dager',
          DAYS_31_TO_60: '31 til 60 dager',
          DAYS_61_TO_90: 'Mer enn 60 dager',
          LESS_THAN_15_DAYS: 'Repareret på mindre enn 15 dager',
          '16_TO_30_DAYS': 'Repareret på 16-30 dager',
          '31_TO_60_DAYS': 'Repareret på 31-60 dager',
          MORE_THAN_60_DAYS: 'Repareret på 61-90 dager',
          AIR: 'Luft',
          METHANE: 'Metan (CH4)',
          CARBON_DIOXIDE: 'Karbondioksid (CO2)',
          HYDROGEN: 'Hydrogen (H2)',
          HELIUM: 'Helium (He)',
          NITROGEN: 'Nitrogen (N2)',
          REFRIGERANT: 'Kjølemiddel (HFK)',
          ARGON: 'Argon (Ar)',
          OXYGEN: 'Oksygen (O)',
          WATER: 'Vann (Damp)',
          DEFAULT: 'Ingen valgt',
          HOLE: 'Hull',
          CRACK: 'Sprekk',
          FITTING: 'Kobling',
          FLANGE: 'Flens',
          'GLAND/SEAL': 'Pakning/Tetning',
          THREAD: 'Gjenge',
          'TUBE/HOSE': 'Rør/Slange',
          COMPRESSED_AIR: 'Komprimert luft',
          VACUUM: 'Vakuum',
          STEAM: 'Damp',
          PROCESS_GAS: 'Prosessgass',
          LIQUID: 'Væske',
          ROTARY_SCREW: 'Roterende skrue',
          RECIPROCATING: 'Stempel',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Sentrifugal',
          CONNECTOR: 'Kobling',
          CONTROL_VALVE: 'Kontrollventil',
          HOSE: 'Slange',
          TUBE_FITTING: 'Rørkobling',
          METER: 'Måler',
          OPEN_ENDED_LINE: 'Åpen linje',
          PRESSURE_RELIEF_VALVE: 'Trykkavlastningsventil',
          PUMP_SEAL: 'Pumpetetning',
          REGULATOR: 'Regulator',
          VALVE: 'Ventil',
          LEVEL_CONTROLLER: 'Nivåkontroller',
          POSITIONER: 'Positioonsgiver',
          PRESSURE_CONTROLLER: 'Trykkontroller',
          TRANSDUCER: 'Transduser',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Generisk pneumatisk instrument',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Generisk stempelkompressor',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Generisk sentrifugalkompressor',
        },
        leakRecordPDF: 'Registrering • Eksportert {{date}}',
        leakTag: 'Smarte etiketter • Eksportert {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Kompressorens merking',
          COMPRESSOR_MAKE: 'Kompressorfabrikat',
          COMPRESSOR_MODEL: 'Kompressormodell',
          COMPRESSOR_TYPE: 'Kompressortype',
          NAMEPLATE_HP: 'Navneskilt HK',
          NAMEPLATE_CAPACITY: 'Navneskilt kapasitet (m³/h)',
          MOTOR_EFFICIENCY: 'Motoreffektivitet (%)',
          MOTOR_VOLTAGE: 'Motorspenning',
          FULL_LOAD_CURRENT: 'Full last strøm (Ampere)',
          FULL_LOAD_INPUT_POWER: 'Full last inngangseffekt (kW)',
          RATED_PRESSURE: 'Nominell trykk (kPag)',
          PRESSURE_SETPOINT: 'Trykkinnstilling (kPag)',
          CONTROL: 'Kontroll',
          TOTAL_AIR_STORAGE_SIZE: 'Total luftlagringsstørrelse (l)',
          IS_ACTIVE: 'Kompressor Aktiv / Av',
          OPERATION_TYPE: 'Trim / Grunnlast',
          PERCENT_LOAD: '% Last',
          COMPRESSOR_SETPOINT_SEU: 'Kompressor innstilling (kPag)',
          COMPRESSOR_FLOW_SEU: 'Kompressor strømningshastighet (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'Systemtrykkinnstilling (kPag)',
          SYSTEM_MEASURED_FLOW: 'System målt strømningshastighet (m³/h)',
          DATE_TIME_RUNNING_STATE: 'Dato og klokkeslett for driftstilstand',
          NOTES: 'Notater',
          NAMEPLATE: 'Kompressorens navneplate',
          DATASHEET: 'Kompressorens datablad',
          BHP: 'BHP',
          OPERATING_HOURS: 'Driftstimer',
          LOADED_PERCENT_TIME_RUNNING: '% Driftstid',
          LOADED_PERCENT_FULL_LOAD_BHP: '% Full last BHP (Kapasitet)',
          UNLOADED_PERCENT_TIME_RUNNING: '% Driftstid',
          UNLOADED_PERCENT_FULL_LOAD_BHP: '% Full last BHP (Kapasitet)',
          ENERGY_COST: 'Aggregert elektrisk kostnad ($)',
          ANNUAL_ENERGY_COST: 'Årlig energikostnad ($)',
          ANNUAL_ENERGY_KWH: 'Årlig energiforbruk (kWh)',
          COST_FULLY_LOADED: 'Kostnad ved fullt lastet ($)',
          COST_UNLOADED: 'Kostnad når tømt ($)',
          KWH_PER_YEAR_LOADED: 'kWh per år når lastet',
          KWH_PER_YEAR_UNLOADED: 'kWh per år når tømt',
          ENERGY_COST_PER_UNIT: '$/m³',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Navneskilt kapasitet (cfm)',
          FULL_LOAD_INPUT_POWER: 'Full last inngangseffekt (BTU/h)',
          RATED_PRESSURE: 'Nominell trykk (psi)',
          PRESSURE_SETPOINT: 'Trykkinnstilling (psi)',
          TOTAL_AIR_STORAGE_SIZE: 'Total luftlagringsstørrelse (gal)',
          COMPRESSOR_SETPOINT_SEU: 'Kompressor innstilling (psi)',
          COMPRESSOR_FLOW_SEU: 'Kompressor strømningshastighet (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'Systemtrykkinnstilling (psi)',
          SYSTEM_MEASURED_FLOW: 'System målt strømningshastighet (cfm)',
          ENERGY_COST_PER_UNIT: '$/cf',
        },
      },
      energyFields: {
        labels: {
          UTILITY_PROVIDER: 'Strømleverandør',
          ENERGY_COST: '$/kWh',
          ENERGY_UNIT: 'Enhet',
          REBATE_COST: '$/kWh',
          REBATE_UNIT: 'Enhet',
          ENERGY_SOURCES: 'Energikilder',
          FE_HOURS_OPERATION: 'Driftstimer',
          FE_ENERGY_COST: 'Energikostnad ($/kWh)',
          FE_EFFICIENCY: 'Effektivitet (kW/m³/h)',
          KWH_PER_FLOW: 'kWh/m³/h',
          COST_PER_FLOW: '$/m³/h',
        },
        labelsImperial: {
          FE_EFFICIENCY: 'Effektivitet (kW/cfm)',
          KWH_PER_FLOW: 'kWh/cfm',
          COST_PER_FLOW: '$/cfm',
        },
      },
    },
    hooks: {
      useError: {
        message: 'Valideringsfeil ved forespørsel. Vennligst sjekk feltene i skjemaet',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Registrer en enhet',
          help: 'Hjelp',
          metric: 'Metrisk',
          imperial: 'Imperial',
          downloadApk: 'Last ned APK',
          downloadOta: 'Last ned fil',
        },
        otaDialog: {
          title: 'Last ned fil',
          downloadButton: 'Last ned',
          serialNumberLabel: 'Enhetens serienummer',
          serialNumberRequired: 'Serienummer er påkrevd',
          fileDownloaded: 'Fil {{fileName}} lastet ned',
          errorFetchingFile: 'Feil ved henting av fil. Vennligst prøv igjen senere',
          selectSerialNumber: 'Velg et enhetsserienummer',
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'Du er på Essential-planen.',
            dialogTwo: 'Klikk ',
            dialogThree: 'her',
            dialogFour: 'for å kjøpe en ny administratortillatelse eller kontakt ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable: 'Vi opplever tekniske problemer. Vår tjeneste kan bli påvirket. Beklager bryet.',
          downForMaintenance: 'Vi er nede for vedlikehold. Vennligst prøv igjen senere.',
          tagsManagement: {
            dialogOne: 'Kjøp mer',
            dialogTwo: 'PROSARIS Smarte Etiketter',
            dialogThree: ' her！',
          },
        },
        mobileNav: {
          index: {
            menu: 'Meny',
            editProfile: 'Rediger profil',
            logout: 'Logg ut',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Tøm alt',
            readAll: 'Merk alt som lest',
            noNotifications: 'Ingen varsler',
          },
        },
        index: {
          dashboard: 'Dashbord',
          records: 'Oppføringer',
          actions: 'Handlinger',
          company: 'Selskap',
          tags: 'Etiketter',
        },
        loggedInAs: 'Logget inn som',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: 'Kan ikke bestemme etiketten etter feltet {{field}}',
        cannontDeterminByLabel: 'Kan ikke bestemme feltet etter etiketten {{label}}',
        active: 'Aktiv',
        closed: 'Lukket',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Dashbord',
        records: 'Oppføringer',
        actions: 'Handlinger',
        company: 'Selskap',
        tags: 'Etiketter',
        redirecting: 'Omdirigerer deg...',
      },
      account: {
        index: {
          updatePassword: 'Passord oppdatert med suksess.',
          accountDeletedSuccess: 'Kontoen {{user}} er blitt slettet med suksess',
          accountDeletedError: 'Kan ikke slette kontoen for øyeblikket. Vennligst prøv igjen senere.',
          authenticationError: 'Feil ved autentisering av bruker',
          personalDetails: 'Personlige detaljer',
          currentPasswordIsRequired: 'Nåværende passord er påkrevd',
          username: 'Brukernavn',
          changePassword: 'Endre passord',
          currentPassword: 'Nåværende passord',
          profilePicture: 'Profilbilde',
          dangerZone: 'Fareområde',
          integrations: 'Integrasjoner',
          sosShipments: 'SOS Lagerbeholdning',
          sosSyncMessage:
            'Denne prosessen aktiverer SOS-bestillingsbehandling i Prosaris med de nyeste dataene fra SOS Lagerbeholdning. Selv om denne prosessen vanligvis styres automatisk hver 4. time gjennom en planlagt oppgave, lar bruk av denne knappen deg initiere prosessen manuelt.',
          sosSyncSuccess: 'SOS-synkronisering har blitt utløst vellykket',
          triggerSosSync: 'Start SOS-synkronisering',
          hubspot: 'HubSpot',
          hubspotSyncMessage:
            'Denne prosessen overfører øyeblikkelig de nyeste dataene fra plattformen til HubSpot. Denne synkroniseringen skjer automatisk hver time gjennom en planlagt oppgave, men ved å klikke på knappen kan du manuelt starte synkroniseringen når det er nødvendig.',
          hubspotSyncSuccess: 'HubSpot-synkronisering har blitt utløst vellykket',
          triggerHubspotSync: 'Start HubSpot-synkronisering',
          deleteAccountTitle: 'Slett konto',
          deleteAccountMessage: 'Kontoen vil ikke lenger være tilgjengelig, og all personlig informasjon vil bli permanent slettet.',
          alertTitle: 'Vennligst bekreft sletting av konto',
          deleteDialog: {
            dialogTwo: 'Sletting av kontoen din vil permanent fjerne all din personlige informasjon fra vår database.',
            dialogThree: 'DENNE HANDLINGEN KAN IKKE ANGRES.',
            dialogFour: 'Vennligst skriv inn ditt passord for å fortsette.',
          },
        },
        toolBar: {
          cacheClearedSuccess: 'Cache ryddet med suksess!',
          editProfile: 'Rediger profil',
          clearCache: 'Tøm cache',
          alertTitle: 'Vennligst bekreft tømming av app-cache',
          deleteAll: 'Slett alt',
          accordionDialog: {
            dialogOne: 'Dette vil slette alle lagrede innstillinger for følgende sider:',
            dialogTwo: 'Diagrammer, diagramfiltre, tidsramme',
            dialogThree: 'Filtre, Sortering, Kolonner, Forhåndsvisning',
            dialogFour: 'Filtre, kolonner',
            dialogFive: 'Valgt selskap',
            dialogSix: 'Filtre, kolonner',
          },
          deleteSelective: 'Slett selektivt',
          selectiveDialog: 'Vennligst velg de lagrede innstillingene du vil slette:',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Tidsramme',
            CHART_FILTERS: 'Diagramfiltre',
            CHARTS: 'Diagrammer',
          },
          Records: {
            HIGHLIGHTED: 'Forhåndsvisning',
            COLUMNS: 'Kolonner',
            FILTERS: 'Filtre',
            SORT_COLUMN: 'Sortering',
          },
          Actions: {
            FILTERS: 'Filtre',
            COLUMNS: 'Kolonner',
          },
          Company: {
            SELECTED: 'Valgt selskap',
          },
          Tags: {
            FILTERS: 'Filtre',
            COLUMNS: 'Kolonner',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Selskap',
          'leakStatus.building': 'Enhet/Utstyr',
          'leakStatus.facility': 'Anlegg',
          'leakStatus.leakTag': 'Smart Merke #/Arbeidsref. #',
        },
        tableBody: {
          noAssignmentsToDisplay: 'Ingen oppgaver å vise',
        },
        filtersCommonWords: {
          recordsFilter: 'Postfilter',
          column: 'Kolonne',
          selectAColumn: 'Velg en kolonne...',
          addSearchTerm: 'Legg til et søkeord...',
          filtersArentAvailable: 'Filtre er ikke tilgjengelige',
          value: 'Verdi',
          exportCSV: 'Eksporter CSV',
          show: 'Vis',
          loadMoreRecords: 'Last inn flere poster',
          selectedAllRecords: 'Valgt alle poster ({{totalNum}})',
          selectedFromRecords: 'Valgt {{selectedNum}} av {{totalNum}} poster',
          reset: 'Tilbakestill',
        },
        invalidColumn: 'Ugyldig kolonne {{column}}',
        assignedActionRecords: 'Tildelte handlingsposter',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: 'Har du glemt passordet ditt?',
          successMessage: 'Vi har sendt en bekreftelses-e-post til',
        },
        authSuccess: {
          thanks: 'Takk!',
          backToLogin: 'Tilbake til innlogging',
        },
        loginView: {
          forgotPass: 'Glemt passord?',
          createAccount: 'Opprett en konto',
          passwordIsRequired: 'Passord er nødvendig',
          resetPassword: 'Tilbakestill passord',
          contactSupport: 'Vennligst kontakt support@prosaris.ca',
          incorrectEmailOrPassword: 'Feil e-post eller passord',
          resetPassDialog: {
            dialogOne: 'Du må tilbakestille passordet ditt før du kan fortsette.',
            dialogTwo: 'En e-post for tilbakestilling av passord har blitt sendt til deg med instruksjoner.',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'Passordet ble tilbakestilt',
          enterNewPassword: 'Skriv inn ditt nye passord nedenfor.',
          passwordChanged: 'Ditt passord er endret.',
        },
        createCompanyView: {
          createCompany: 'Opprett et selskap',
          companyName: 'Bedriftnavn',
          successCreatingCompany: 'Din bedrift har blitt opprettet.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Uventet feil ved lasting av lekkasjedata',
          calculatingError: 'Uventet feil under beregning',
          dbMeasured: 'Målt Db',
          calculate: 'Beregn',
          calculating: 'Beregner...',
          outflowCoefficient: 'Utløpskoeffisient',
          kwhPerYear: 'Kwh/år',
        },
      },
      companies: {
        commonUsedWords: {
          assignedAdminLicenses: 'Tildelte administratorlisenser',
          company: 'Selskap',
          totalAccounts: 'Totale kontoer',
          email: 'E-post',
          name: 'Navn',
          loadCompanyUsersError: 'Kunne ikke laste bedriftets brukere. Vennligst prøv igjen senere',
          activated: 'Aktivert',
          deactivated: 'Deaktivert',
          activating: 'Aktivering pågår',
          deactivating: 'Deaktivering pågår',
          deactivate: 'Deaktiver',
          activate: 'Aktiver',
          set: 'Sett',
          youAreGoingTo: 'Du skal',
          areYouSure: 'Er du sikker?',
          companyNameIsRequired: 'Bedriftnavn er nødvendig',
          editCompany: 'Rediger selskap',
          createCompany: 'Opprett selskap',
          viewHistory: 'Vis historikk',
          onEssentialPlan: 'Du er på en essensiell plan. Vennligst kontakt support@prosaris.ca',
          exportCSV: 'Eksporter CSV',
        },
        groupPerformance: {
          title: 'Gruppeytelse',
          normalizeData: 'Normaliser Data',
          selectTimeline: 'Velg Tidslinje',
          selectCriteria: 'Velg Kriterier',
        },
        charts: {
          chart: {
            criteria: {
              KWH: 'Totalt kWh/år',
              LOSS: 'Totalt Tap ($/år)',
              FLOW: 'Total Masseflyt',
            },
            noDataAvailable: 'Ingen data tilgjengelig',
            topNCompanies: 'Topp {{numCompanies}} Selskaper',
          },
        },
        accounts: {
          accountDialog: {
            companyIsRequired: 'Selskap kreves',
            roleIsRequired: 'Rolle kreves',
            createAccountSuccessMessage: 'Konto opprettet med suksess',
            emailTakenErrorMessage: 'En konto med denne e-posten finnes allerede i systemet',
            errorWhileCreatingAccountMessage: 'Feil ved opprettelse av konto. Vennligst prøv igjen senere.',
            changeAccountSuccessMessage: 'Kontoen ble oppdatert med suksess',
            errorWhileChangingAccountMessage: 'Feil ved oppdatering av konto. Vennligst prøv igjen.',
            editAccount: 'Rediger konto',
            createAccount: 'Opprett konto',
            role: 'Rolle',
          },
          changeOwner: {
            changeOwnerMessage: 'Ønsker du også å endre eier for lekkasjeposter knyttet til denne kontoen?',
            no: 'Nei, behold nåværende konto',
          },
          contentCell: {
            loadAccountRolesError: 'Klarte ikke å laste kontoroller informasjon. Vennligst prøv igjen senere',
            changeRoleSuccessMessage: 'Konto {{email}} har en ny rolle',
            changeRoleErrorMessage: 'Feil ved oppdatering av rolle for konto {{email}}. Vennligst prøv igjen senere',
            editUser: 'Rediger bruker',
            adminSetPassword: 'Administrator setter passord',
            adminSetSubRenewalDate: 'Abonnementsfornyelsesdato satt av administrator',
            resetPassword: 'Tilbakestill passord',
            user: 'Bruker',
            deleteUser: 'Slett bruker',
          },
          index: {
            noUsersToDisplay: 'Ingen brukere å vise',
            changeStatusSuccessMessage: 'Bruker {{isActive}} med suksess',
            changeStatusErrorMessage: 'Feil ved {{isActive}} bruker. Vennligst prøv igjen',
            changeOwnerSuccessMessage: 'Poster ble overført til en ny eier',
            changeOwnerErrorMessage: 'Kan ikke overføre poster til ny eier. Vennligst prøv igjen senere',
            deleteAccountMutationSuccessMessage: 'Konto {{email}} har blitt slettet med suksess',
            deleteAccountMutationErrorMessage: 'Kan ikke slette {{email}} konto for øyeblikket. Vennligst prøv igjen senere',
            resetAccountPassWordSuccessMessage: 'E-post for tilbakestilling av passord sendt med suksess',
            users: 'brukere',
            noneAvailable: 'Ingen tilgjengelig',
            of: 'av',
            contactSupport: 'Vennligst kontakt support@prosaris.ca for mer informasjon om bedrift administrator lisenser',
            findAccount: 'Finn konto',
            placeholder: 'Skriv navn eller e-post...',
            tooltip: 'Du trenger minst en bedriftadministratorlisens før du kan invitere brukere',
            invite: 'Inviter',
            alertDialog: {
              messageOne: 'Vennligst bekreft brukerens forespørsel om tilbakestilling av passord',
              messageTwo: 'Vennligst bekreft endring av kontostatus',
              messageThree: 'Vennligst bekreft sletting av konto',
            },
            sendAPasswordResetEmail: 'Du skal sende en e-post for tilbakestilling av passord for følgende konto:',
            confirmMessage: 'Ønsker du å fortsette?',
            followingAccount: 'følgende konto:',
            delete: 'SLETTE',
            userCreatedDialogTitle: 'Lenke for tilbakestilling av brukerpassord sendt',
            userCreatedDialogDescription:
              'En lenke for tilbakestilling av passord er sendt til brukerens e-postadresse. Du kan også kopiere lenken nedenfor.',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'Kontopassord oppdatert med suksess',
            changedUserPasswordErrorMessage: 'Feil ved oppdatering av konto. Vennligst prøv igjen.',
            setNewPassword: 'Angi nytt passord',
            accountPasswordUpdated: 'Kontopassord oppdatert',
            accountPasswordUpdatedDescription:
              'Kontopassordet har blitt oppdatert. Du kan klikke på knappen nedenfor for å kopiere det nye passordet.',
          },
          adminSetSubRenewalDate: {
            title: 'Angi abonnementsfornyelsesdato',
            subRenewalDate: 'Abonnementsfornyelsesdato',
            changedSubRenewalDateSuccessMessage: 'Abonnementsfornyelsesdato oppdatert',
            changedSubRenewalDateErrorMessage: 'Feil ved oppdatering av konto. Vennligst prøv igjen.',
          },
          setPassword: {
            automatic: 'Automatisk generer et sterkt passord',
            manual: 'Opprett passord manuelt',
            copyLinkInstruction: 'Du vil kunne se og kopiere passordet i neste trinn',
          },
          userCreatedDialog: {
            title: 'Bruker opprettet',
            description:
              'Din nye bruker kan nå begynne å bruke plattformen. Brukeren vil også motta en lenke via e-post for å tilbakestille passordet sitt.',
            passwordCopied: 'Passord kopiert til utklippstavle',
            passwordResetLinkCopied: 'Tilbakestill passord-lenke kopiert til utklippstavlen',
            copyPassword: 'Kopier passord',
            copyPasswordResetLink: 'Kopier lenke for tilbakestilling av passord',
          },
          useRoleOptions: {
            notAvailable: '(ikke tilgjengelig)',
            adminSeatTaken: '(administrator setet er tatt)',
            allSeatsAreTaken: '(alle {{numSeats}} seter er tatt)',
            adminRolesTitle: 'Administratorroller',
            roles: {
              superAdmin: 'Hovedadministrator',
              companyAdmin: 'Bedrift (Administrator)',
              superCompanyAdmin: 'Selskap  (Administrator)',
              companyRead: 'Bedrift (Lese)',
              superAdminRead: 'Hovedadministrator (kun lesing)',
              integrator: 'Integrator',
              auditor: 'Revisor',
              user: 'Bruker',
              essential: 'Prosaris Essential',
              enterpriseAdmin: 'Bedriftadministrator',
            },
            rolesDescriptions: {
              superAdmin: 'Tillatt å ha full kontroll over flere selskaper.',
              companyAdmin: 'Tillatt å vise, redigere og tildele bedriftposter.',
              superCompanyAdmin: 'Tillatt å ha full kontroll over bedriftposter og brukere.',
              companyRead: 'Tillatt å vise bedriftposter i skrivebeskyttet modus.',
              superAdminRead: 'Tillatt å ha skrivebeskyttet tilgang til alle selskaper.',
              integrator: 'Tillater tredjeparts integrasjoner å administrere selskaper og kontoer.',
              auditor: 'For å tillate revisjon av lekkasjeposter.',
              user: 'Tillatt å opprette/oppdatere/slette egne poster.',
              essential: 'En rolle med begrenset funksjonalitet.',
              enterpriseAdmin: 'Tillatt å ha full kontroll over bedriftposter og brukere.',
            },
            nonAdminRolesTitle: 'Ikke-administratorroller',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Kunne ikke laste inn revisjoner. Vennligst prøv igjen senere',
            audits: 'revisjoner',
            noAuditsToDisplay: 'Ingen revisjoner å vise',
            findByAuditorEmail: 'Finn ved revisore-post',
            placeholder: 'Skriv revisore-post...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: 'Anleggsnavn er påkrevd',
              hoursYearlyIsRequired: 'Totale timer/år er påkrevd',
              totalHoursYearly: 'Totale timer/år kan ikke overstige 8760',
              invalidPhoneNumber: 'Ugyldig telefonnummer',
              createErrorMessage: 'Timer x Skift kan ikke overstige 24 timer',
              loadFacilityErrorMessage: 'Kunne ikke laste inn anlegget. Vennligst prøv igjen senere',
              createFacilitySuccessMessage: 'Anlegg opprettet med suksess',
              createFacilityErrorMessage: 'Feil ved opprettelse av anlegg. Vennligst prøv igjen senere.',
              changeFacilitySuccessMessage: 'Anlegg oppdatert med suksess',
              changeFacilityErrorMessage: 'Feil ved oppdatering av anlegg. Vennligst prøv igjen.',
              facilityName: 'Anleggsnavn',
              abbreviation: 'Forkortelse',
              address: 'Adresse',
              enterALocation: 'Skriv inn en plassering',
              tooltip: 'for energiledelsesaktiviteter, også rapportgodkjenner',
              energyMgtOwner: 'Energiadmin eier',
              title: 'Tittel',
              phone: 'Telefon',
              tooltipTwo: 'for løsningsfornyelser og generelle konto problemer',
              accountOwner: 'Kontoeier',
              manufacturingArea: 'Produksjonsområde',
              size: 'Størrelse',
              unit: 'Enhet',
              hoursOfOperation: 'Driftstimer',
              auditFrequency: 'Revisjonsfrekvens',
            },
            operationHours: {
              daysPerWeek: 'Dager/uke',
              shiftsPerDay: 'Skift/dag',
              hoursPerShift: 'Timer/skift',
              weeksPerYear: '52 uker/år',
              totalHoursYearly: 'Totalt = Timer/år',
            },
            constants: {
              constant: 'Konstant',
              monthly: 'Månedlig',
              threeMonth: '3 Måneder',
              fourMonth: '4 Måneder',
              sixMonth: '6 Måneder',
              nineMonth: '9 Måneder',
              annual: 'Årlig',
              sqft: 'Kvadratfot [SQFT]',
              sqm: 'Kvadratmeter [SQM]',
            },
          },
          index: {
            users: 'Brukere',
            devices: 'Enheter',
            facility: 'Anlegg',
            energy: 'Energi',
            seu: 'SEUs - Comp.',
            enpi: 'EnPIs/EnBs',
            routes: 'Ruter',
            audit: 'Revisjoner',
            comingSoon: 'Kommer snart...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'Kunne ikke laste EnPIs. Vennligst prøv igjen senere',
            createEnpisSuccessMessage: 'EnPIs opprettet vellykket',
            createEnpisErrorMessage: 'Feil under oppretting av EnPIs. Vennligst prøv igjen senere.',
            changeEnpisSuccessMessage: 'EnPIs oppdatert vellykket',
            changeEnpisErrorMessage: 'Feil under oppdatering av EnPIs. Vennligst prøv igjen.',
            showHistory: 'Vis historikk',
          },
          changeHistory: {
            bulkOperation: 'som en masseoperasjon',
            details: 'detaljer',
            created: 'opprettet',
            updated: 'oppdatert',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'Historikk',
            noHistory: 'Ingen historikk',
          },
        },
        energy: {
          index: {
            utilProvider: 'Strømleverandør',
            energyUse: 'Energiforbruk og forbrukstype',
            error: {
              loadFacility: 'Kunne ikke laste anlegget. Vennligst prøv igjen senere',
              loadEnergy: 'Kunne ikke laste energidata. Vennligst prøv igjen senere',
              loadUtilProviderLogo: 'Kunne ikke laste strømleverandørens logo. Vennligst prøv igjen senere',
              createEnergy: 'Kunne ikke opprette energidata. Vennligst prøv igjen senere',
              createUtilProviderLogo: 'Kunne ikke laste opp strømleverandørens logo. Vennligst prøv igjen senere',
              updateEnergy: 'Kunne ikke oppdatere energidata. Vennligst prøv igjen senere',
              deleteUtilProviderLogo: 'Kunne ikke slette strømleverandørens logo. Vennligst prøv igjen senere',
            },
            success: {
              createEnergy: 'Energidata opprettet',
              updateEnergy: 'Energidata oppdatert',
            },
          },
          utilProvider: {
            index: {
              costOfEnergy: 'Energikostnad',
              energySources: 'Energikilder',
              incentiveRate: 'Incentivrate',
              per: 'per',
              validations: {
                utilProviderIsRequired: 'Strømleverandør er påkrevd',
                costOfEnergyIsRequired: 'Energikostnad er påkrevd',
                costOfEnergyMustBeNumber: 'Energikostnad må være et tall',
                costOfEnergyMustBeGreaterThanZero: 'Energikostnad må være større enn eller lik 0',
                costOfRebateMustBeAtLeatZero: 'Rabattkostnaden må være minst 0',
                costOfRebateMustBeNumber: 'Rabattkostnaden må være et tall',
              },
            },
            UtilProviderLogo: {
              error: {
                fileSize: 'Filstørrelsen må være mindre enn 1 MB',
                preview: 'Kunne ikke forhåndsvise bilde',
              },
              logo: 'Logo',
              addLogo: 'Legg til logo',
              removeLogo: 'Fjern logo',
            },
            EnergySources: {
              energySource: 'Energikilde',
              percentage: 'Prosent',
              selectEnergySource: 'Velg energikilde',
              addEnergySource: 'Legg til energikilde',
              totalPercentage: 'Total prosentandel',
              mustBeEqualTo100: '(må være lik 100%)',
              constants: {
                energySources: {
                  NATURAL_GAS: 'Naturgass',
                  FUEL_OIL: 'Fyringsolje',
                  DIESEL: 'Diesel',
                  GASOLINE: 'Bensin',
                  PROPANE: 'Propan',
                  COAL: 'Kull',
                  WIND: 'Vind',
                  GEOTHERMAL: 'Geotermisk',
                  BIOMASS: 'Biomasse',
                  STEAM: 'Damp',
                  COMPRESSED_AIR: 'Trykkluft',
                  CHILLED_WATER: 'Kjølevann',
                  NUCLEAR: 'Kjerne',
                  HYDRO: 'Vannkraft',
                  SOLAR: 'Sol',
                },
              },
            },
          },
          energyUse: {
            index: {
              currentCalcApproach: 'Nåværende beregningsmetode',
              currentCalcApproachMessage:
                "Denne beregningsmetoden gjelder for alle 'Kostnad for energi'-felt som standard, med mindre brukeren har overstyrt den.",
              alertTitle: 'Tilbakestill beregningsmetode',
              alertMessageOne:
                "Denne handlingen vil stoppe bruken av den nåværende beregningsmetoden for alle 'Kostnad for energi'-feltene fremover.",
              alertMessageTwo: 'Vennligst merk at denne endringen ikke vil påvirke eksisterende poster.',
              alertMessageThree: 'Er du sikker på at du vil fortsette med tilbakestillingen?',
            },
            ReportType: {
              calcApproach: 'Beregningstilnærming',
              constants: {
                calculations: {
                  FIXED: 'Fast effektivitet',
                  USDOE: 'US DoE',
                  NREL: 'NREL kapittel 22',
                },
              },
              setAsCurrent: 'Sett som nåværende',
              setAsCurrentMessage: "Denne beregningsmetoden vil bli brukt for alle 'Kostnad for energi'-felt fremover.",
            },
            calculations: {
              FixedEfficiency: {
                validations: {
                  efficiencyMustBeGreaterThanZero: 'Effektiviteten må være større enn eller lik 0',
                  efficiencyMustBeNumber: 'Effektiviteten må være et tall',
                  operationHoursMustBeGreaterThanZero: 'Driftstiden må være større enn eller lik 0',
                  operationHoursMustBeLessThan8760: 'Driftstiden må være mindre enn 8760',
                  operationHoursMustBeNumber: 'Driftstiden må være et tall',
                },
              },
              UsDoe: {
                loaded: 'Ladet',
                unloaded: 'Avlastet',
                calcSummary: 'Beregning Sammendrag',
                currentOperatingSystems: 'Nåværende operativsystemer',
                noCompsFoundAddCompOne: 'Ingen kompressorer funnet. Legg til en kompressor fra',
                noCompsFoundAddCompTwo: 'fanen over.',
              },
            },
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Feil ved opplasting av navneskilt. Vennligst prøv igjen senere.',
            dataSheetError: 'Feil ved opplasting av datablad. Vennligst prøv igjen senere.',
            createSuccess: 'Kompressor {{tag}} opprettet',
            createError: 'Kunne ikke opprette kompressor. Vennligst prøv igjen senere.',
            createCompressor: 'Opprett kompressor',
          },
          compressorFile: {
            tip: 'Legg til fil',
            removeFile: 'Fjern fil',
            removeImage: 'Fjern bilde',
            title: 'Bekreft sletting av kompressor {{prop}}',
            proceed: 'Vil du fortsette?',
          },
          compressorForm: {
            compTagIsReq: 'Kompressorens merking er påkrevd',
            hpMustBeNum: 'Navneskiltet HK må være et tall',
            capMustBeNum: 'Navneskiltet kapasitet må være et tall',
            motorEffMustBeNum: 'Motoreffektiviteten må være et tall',
            motorVoltMustBeNum: 'Motorspenningen må være et tall',
            loadMustBeNum: 'Full last strøm må være et tall',
            loadInputMustBeNum: 'Full last inngangseffekt må være et tall',
            pressureMustBeNum: 'Nominelt trykk må være et tall',
            setpointMustBeNum: 'Trykkinnstillingen må være et tall',
            airMustBeNum: 'Luftlagringsstørrelsen må være et tall',
            addImage: 'Legg til bilde',
            addFile: 'Legg til fil',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Roterende skrue',
              RECIPROCATING: 'Stempel',
              CENTRIFUGAL: 'Sentrifugal',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Last/avlast – Oljefri',
              loadUnload1Gal: 'Last/avlast (1 gal/CFM)',
              loadUnload3Gal: 'Last/avlast (3 gal/CFM)',
              loadUnload5Gal: 'Last/avlast (5 gal/CFM)',
              loadUnload10Gal: 'Last/avlast (10 gal/CFM)',
              ivmWithBlowdown: 'Innløpsventilmodulasjon (uten utblåsning)',
              ivmWithoutBlowdown: 'Innløpsventilmodulasjon (med utblåsning)',
              variableDisplacement: 'Variabel forskyvning',
              vsdWithUnloading: 'Variabel hastighetsdrift med avlasting',
              vsdWithStopping: 'Variabel hastighetsdrift med stopp',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'På/av-kontroll',
              reciprocatingLoadUnload: 'Last/avlast',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV med utblåsning',
              centrifugalInletIbv: 'IGV med utblåsning',
            },
          },
          contentCell: {
            min: '% må være minst 0',
            max: '% kan ikke være mer enn 100',
            max150: '% kan ikke være mer enn 150',
            typeError: 'Belastning må være en gyldig prosentandel',
            setpointMustBeNum: 'Kompressorinnstillingspunkt må være et tall',
            flowMustBeNum: 'Kompressorstrømning må være et tall',
            pressureSetpointMustBeNum: 'Systemtrykkinnstillingspunkt må være et tall',
            measuredFlowMustBeNum: 'Systemmålt strømning må være et tall',
            dateTime: 'Velg dato og tid',
            active: 'AKTIV',
            off: 'AV',
            trim: 'Trim',
            baseLoad: 'Baselast',
          },
          index: {
            error: 'Kunne ikke laste kompressorer. Vennligst prøv igjen senere',
            nameplateError: 'Kunne ikke laste navneskilt. Vennligst prøv igjen senere',
            dataSheetError: 'Kunne ikke laste datablad. Vennligst prøv igjen senere',
            changeCompSuccess: 'Kompressor oppdatert',
            changeCompError: 'Feil ved oppdatering av kompressor. Vennligst prøv igjen.',
            deleteCompSuccess: 'Kompressor slettet',
            deleteCompError: 'Feil ved sletting av kompressor. Vennligst prøv igjen.',
            createCompNameplateSuccess: 'Kompressornavnskilt lastet opp',
            createCompNameplateError: 'Feil ved opplasting av navneskilt. Vennligst prøv igjen senere.',
            deleteCompNameplateSuccess: 'Kompressornavnskilt slettet',
            deleteCompNameplateError: 'Feil ved sletting av kompressornavnskilt. Vennligst prøv igjen.',
            createCompDataSheetSuccess: 'Kompressordatablad lastet opp',
            createCompDataSheetError: 'Feil ved opplasting av datablad. Vennligst prøv igjen senere.',
            deleteCompDataSheetSuccess: 'Kompressordatablad slettet',
            deleteCompDataSheetError: 'Feil ved sletting av kompressordatablad. Vennligst prøv igjen.',
            changeCompanyCompSuccess: 'Systemdata oppdatert',
            changeCompanyCompError: 'Feil ved oppdatering av systemdata. Vennligst prøv igjen.',
            compDesignData: 'Kompressordata',
            comp: 'Kompressorer:',
            addComp: 'Legg til kompressor',
            editCompPermission: 'Du trenger en adminlisens for å redigere kompressoren. Vennligst kontakt',
            selectOption: 'Velg et alternativ',
            undoChanges: 'Angre endringer',
            selectComp: 'Velg en kompressor ovenfor.',
            noCompsFound: 'Ingen kompressorer funnet.',
            compRunningState: 'Kompressorens driftsstatus',
            noCompsFoundAddComp: 'Ingen kompressorer funnet. Legg til en kompressor fra delen ovenfor.',
            title: 'Bekreft sletting av kompressor',
            areYouSure: 'Er du sikker på at du vil',
            compressor: 'kompressor',
            delete: 'slette',
          },
        },
        devices: {
          contentCell: {
            device: 'Enhet',
            usedByMultipleCompanies: 'brukes av flere selskaper',
          },
          index: {
            noDevicesToDisplay: 'Ingen enheter å vise',
            loadingDevicesErrorMessage: 'Kunne ikke laste inn selskapsenheter. Vennligst prøv igjen senere',
            changeStatusSuccessMessage: 'Enhet {{isActive}} med suksess',
            changeStatusErrorMessage: 'Feil ved {{isActive}} enhet. Vennligst prøv igjen',
            devices: 'enheter',
            findDevice: 'Finn enhet',
            placeholder: 'Skriv inn enhets-ID...',
            activatedOn: 'Aktivert den',
            title: 'Vennligst bekreft endring av enhetsstatus',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} er blitt slettet med suksess',
          deleteCompanyErrorMessage: 'Kunne ikke slette {{companyName}}. Vennligst prøv igjen senere.',
          needAdminLicense: 'Du trenger en admin-lisens for å få tilgang til denne funksjonen. Vennligst kontakt support@prosaris.ca',
          deleteChildCompanyFirst:
            'Kan ikke slette selskap med underselskaper eller kontoer. Vennligst slett alle underselskaper og kontoer først.',
          editSettings: 'Rediger innstillinger',
          company: 'Selskap',
          createASubCompany: 'Opprett et underselskap for {{companyName}}',
          deleteCompany: 'Slett selskap',
          cannotCreateChildCompanyDialog: {
            messageOne: 'Du har nådd det maksimale antallet selskaper som kan opprettes av dine Selskap-kontoer. Vennligst kontakt ',
            messageTwo: 'for ytterligere detaljer.',
          },
          deleteCompanyDialog: {
            title: 'Vennligst bekreft sletting av selskap',
            delete: 'Slett',
            deleting: 'Sletter',
            permanentlyDeleted: 'vil permanent fjerne det fra vår database.',
            cannotBeUndone: 'DENNE HANDLINGEN KAN IKKE ANGRES.',
          },
        },
        companyDialog: {
          industryIsRequired: 'Bransje er påkrevd',
          createLogoErroerMessage: 'Feil ved opplasting av bilde. Vennligst prøv igjen senere.',
          deleteLogoErrorMessage: 'Feil ved sletting av bilde. Vennligst prøv igjen senere.',
          createCompanySuccessMessage: 'Selskapet {{name}} opprettet',
          createCompanyErrorMessage: 'Kunne ikke opprette selskap. Vennligst prøv igjen senere.',
          changeCompanySuccessMessage: 'Selskapet {{name}} oppdatert',
          changeCompanyErrorMessage: 'Feil ved endring av selskapsparameter. Vennligst prøv igjen senere.',
          language: 'Språk',
          industry: 'Bransje',
          country: 'Land',
          currency: 'Valuta',
        },
        companyLogo: {
          setImageErrorMessage: 'Filstørrelsen må være mindre enn 1 MB',
          logo: 'Logo',
          add: 'Legg til',
          removeLogo: 'Fjern logo',
          addLogo: 'Legg til logo',
        },
        index: {
          loadCompaniesErrorMessage: 'Kunne ikke laste inn selskapslisten. Vennligst prøv igjen senere',
          changeCompaniesSectionWidth: 'Endre bredde på selskapsseksjon',
        },
        sidebar: {
          filterCompanies: 'Filtrer selskaper',
          placeholder: 'Skriv inn selskapsnavn',
          myCompany: 'Mitt selskap',
          childCompanies: 'Datterselskaper',
        },
        oldRoutes: {
          index: {
            noFacility: 'Du har ikke definert noen fasiliteter',
            createAFacility: 'Opprett en fasilitet for å begynne å planlegge ruter',
            goToCreateAFacility: 'Gå til å opprette en fasilitet',
            createAFacilityTooltip: 'Du må opprette en fasilitet før du kan begynne å planlegge ruter.',
            save: 'Lagre',
            reset: 'Tilbakestill',
            routePlans: 'Ruteplaner',
            loading: 'Laster...',
            noRoutesAdded: 'Ingen ruter lagt til.',
            buildARouteTooltip: 'Bygg en rute ved hjelp av Ruteplanleggeren.',
            createFloorplanSuccess: 'Suksess! Gulvplan lastet opp!',
            createRouteSuccess: 'Suksess! Rute opprettet!',
          },
          routeBuilder: {
            title: 'Ruteplanlegger',
            missingFields: 'Mangler felt i Excel-filen',
            manuallyBuildARoute: 'Bygg en ny ruteplan manuelt',
            manualInstructions: 'Du kan bygge ruter manuelt eller ved å laste opp en Excel-fil.',
            facility: 'Fasilitet',
            route: 'Rute',
            enterRoute: 'Skriv inn rute',
            addFloorplan: 'Legg til gulvplan',
            floorplanInstructionsTooltip:
              'Legg til et bilde av en gulvplan for å hjelpe deg med å bygge ruter. Ruten vil bli lagt over gulvplanen når den er lagret.',
            change: 'Endre',
            location: 'Plassering',
            enterLocation: 'Skriv inn plassering',
            equipment: 'Utstyr',
            enterEquipment: 'Skriv inn utstyr',
            criticality: 'Kritikalitet',
            enterCriticality: 'Skriv inn kritikalitet',
            description: 'Beskrivelse',
            enterDescription: 'Skriv inn beskrivelse',
            addEquipment: 'Legg til utstyr',
            addRoute: 'Legg til rute',
            downloadTemplate: 'Last ned mal',
          },
          excelReader: {
            loadFromFile: 'Last fra fil',
            instructionsTooltip:
              'Du kan bygge ruter ved å laste opp en Excel-fil der hvert ark representerer en annen plassering i en fasilitet. Hver plassering kan ha flere ruter.',
          },
          graph: {
            controls: {
              addFloorplan: 'Legg til gulvplan',
              toggleLabels: 'Veksle etiketter',
              addWaypoint: 'Legg til veipunkt',
              addEquipment: 'Legg til utstyr',
              swapEquipment: 'Bytt utstyr',
              showLeakStats: 'Vis registreringsstatistikk',
              save: 'Lagre',
              delete: 'Slett',
            },
            routeStats: {
              title: 'Rutestatistikk',
              totalDollarLossPerYear: 'Total $ tap per år:',
              totalKwhLossPerYear: 'Total Kwh tap per år:',
              totalLeakCount: 'Totalt antall oppføringer:',
              totalActiveOpen: 'Totalt aktivt åpent:',
              totalActiveClosed: 'Totalt aktivt lukket:',
              noStatsAvailable: 'Ingen statistikk tilgjengelig:',
              close: 'Lukk',
            },
            nodeDetails: {
              edit: 'Rediger',
              name: 'Navn',
              description: 'Beskrivelse',
              criticality: 'Kritikalitet',
              equipmentInformation: 'Utstyrsinformasjon',
              thisIsAWaypoint: 'Dette er et veipunkt',
              activeOpenLeakCount: 'Antall aktive åpne oppføringer:',
              noActiveLeaksDetected: 'Ingen aktive lekkasjer oppdaget',
              close: 'Lukk',
              nothingToSeeHere: 'Ingenting å se her',
              seeLeakRecords: 'Se oppføringer',
            },
            swapEquipment: {
              title: 'Bytt utstyr',
              selectEquipment: 'Velg utstyret du vil bytte:',
              selectFirst: 'Velg det første utstyret',
              selectSecond: 'Velg det andre utstyret',
              swap: 'Bytt',
              cancel: 'Avbryt',
            },
            addNode: {
              title: 'Legg til',
              waypoint: 'Veipunkt',
              equipment: 'Utstyr',
              afterWhich: 'Etter hvilket utstyr?',
              selectPreceding: 'Velg det foregående utstyret:',
              selectEquipment: 'Velg utstyr',
              name: 'Navn',
              description: 'Beskrivelse',
              criticality: 'Kritikalitet',
              add: 'Legg til',
              cancel: 'Avbryt',
            },
            save: 'Lagre',
            delete: 'Slett',
            successRouteUpdated: 'Ruten ble oppdatert',
            errorRouteUpdated: 'Feil ved oppdatering av rute. Vennligst prøv igjen senere.',
            successRouteDeleted: 'Ruten ble slettet',
            errorRouteDeleted: 'Feil ved sletting av rute. Vennligst prøv igjen senere.',
            successFloorplanUpdated: 'Gulvplanen ble oppdatert',
            errorFloorplanUpdated: 'Feil ved oppdatering av gulvplan. Vennligst prøv igjen senere.',
          },
        },
        routes: {
          commonUsedWords: {
            equipmentNameIsRequired: 'Utstyrsnavn er påkrevd',
            equipmentLocationIsRequired: 'Utstyrsplassering er påkrevd',
            equipmentCriticalityIsRequired: 'Utstyrskritikalitet er påkrevd',
            equipmentDescriptionIsRequired: 'Utstyrsbeskrivelse er påkrevd',
            name: 'Navn',
            description: 'Beskrivelse',
            location: 'Plassering',
            criticality: 'Kritikalitet',
            actions: 'Handlinger',
          },
          index: {
            routePlans: 'Ruteplaner',
            addRoute: 'Legg til rute',
            routeRoutesExample: 'Eksempel på ruter',
            pieceOfEquipment: '{{count}} utstyrsenhet',
            piecesOfEquipment: '{{count}}  utstyrsenheter',
            equipment: 'Utstyr',
            metrics: 'Måleverdier',
            settings: 'Innstillinger',
            noFloorPlan: 'Du har ikke lastet opp en plantegning for denne ruten',
            addFloorPlan: 'Legg til en plantegning for å begynne å planlegge ruten visuelt',
            routeEquipment: 'Ruteutstyr',
            routeMetrics: 'Ruteindikatorer',
            metricsForRoute: 'Indikatorer for ruten',
            totalRecords: 'Totalt antall poster',
            totalOpenRecords: 'Totalt antall åpne poster',
            totalClosedRecords: 'Totalt antall lukkede poster',
            totalLossPerYear: 'Totalt tap per år',
            totalKwhPerYear: 'Totalt kWh per år',
            routeUpdatedSuccessfully: 'Rute oppdatert vellykket',
            routeRemovedSuccessfully: 'Rute fjernet vellykket',
            routeSettings: 'Ruteinnstillinger',
            removeRoute: 'Fjern rute',
            deleteRouteWarning:
              'Fjerning av denne ruten vil fjerne ruten, utstyret og historikken fra systemet, men historiske data tilknyttet poster vil bli bevart.',
            areYouSure: 'Er du sikker på at du vil fjerne denne ruten?',
            warningRemovingRoute: 'Fjerning av ruten vil permanent fjerne den, utstyret og historikken fra systemet.',
            thisActionCannotBeUndone: 'DENNE HANDLINGEN KAN IKKE ANGRES.',
            noFacility: 'Du har ikke definert noen fasiliteter',
            createAFacility: 'Opprett en fasilitet for å begynne å planlegge ruter',
            goToCreateAFacility: 'Gå til å opprette en fasilitet',
            createAFacilityTooltip: 'Du må opprette en fasilitet før du kan begynne å planlegge ruter.',
          },
          addRouteModal: {
            addRouteFor: 'Legg til rute for {{facilityName}}',
            giveYourNewRouteAName: 'Gi ruten din et navn, beskriv plasseringen i anlegget, og legg til det første utstyret',
            routeNameIsRequired: 'Rutenavn er påkrevd',
            routeLocationIsRequired: 'Ruteplassering er påkrevd',
            routeName: 'Rutenavn',
            routeLocation: 'Ruteplassering',
            firstPieceOfEquipment: 'Første utstyrsenhet',
            equipmentName: 'Utstyrsnavn',
            equipmentCriticality: 'Utstyrskritikalitet',
            equipmentLocation: 'Utstyrsplassering',
            equipmentDescription: 'Utstyrsbeskrivelse',
            locationTooltip: 'Beskriv plasseringen av utstyret i anlegget',
            criticalityTooltip: 'Tildel en utstyrs kritikalitetsvurdering (ECR), vanligvis en verdi mellom 1 og 5',
          },
          routeViewer: {
            showLabels: 'Vis etiketter',
            hideLabels: 'Skjul etiketter',
            zoomIn: 'Zoom inn',
            zoomOut: 'Zoom ut',
          },
          routeEditorAddEquipmentAction: {
            addingNewEquipment: 'Å legge til et nytt utstyr legger det til ruten etter det valgte utstyret.',
            addEquipment: 'Legg til utstyr',
          },
          routeEditorAddWaypointAction: {
            waypoint: 'Veipunkt',
            addingNewWaypoint:
              'Å legge til et nytt veipunkt endrer retningen på ruten etter det valgte utstyret, men er ikke et trinn når ruten kjøres.',
            addWaypoint: 'Legg til veipunkt',
          },
          routeEditorEditEquipmentAction: {
            updateEquipment: 'Oppdater utstyr',
          },
          routeEditorEditFloorPlan: {
            floorPlan: 'Gulvplan',
            chooseFloorplan: 'Velg gulvplan',
          },
          routeEditorModal: {
            successRouteUpdated: 'Rute oppdatert vellykket',
            errorRouteUpdated: 'Feil ved oppdatering av rute. Prøv igjen senere.',
            successRouteDeleted: 'Rute slettet vellykket',
            errorRouteDeleted: 'Feil ved sletting av rute. Prøv igjen senere.',
            successFloorplanUpdated: 'Gulvplan oppdatert vellykket',
            errorFloorplanUpdated: 'Feil ved oppdatering av gulvplan. Prøv igjen senere.',
            unsavedChanges: 'Du har ulagrede endringer. Er du sikker på at du vil forlate?',
            back: 'Tilbake',
            editEquipment: 'Rediger utstyr',
            youHaventUploadedFloorPlan: 'Du har ikke lastet opp en gulvplan. Lås opp visuell redigering ved å laste opp en i dag.',
            addFloorPlan: 'Legg til gulvplan',
            addNewEquipment: 'Legg til nytt utstyr',
            removeEquipment: 'Fjern utstyr',
            swapEquipment: 'Bytt utstyr',
            addWaypoint: 'Legg til veipunkt',
            changeFloorplan: 'Endre gulvplan',
            actions: {
              ADD_EQUIPMENT: 'Legg til utstyr',
              DELETE_NODE: 'Slett node',
              SWAP_EQUIPMENT: 'Bytt utstyr',
              ADD_WAYPOINT: 'Legg til veipunkt',
              CHANGE_FLOORPLAN: 'Endre gulvplan',
            },
          },
          routeEditorRemoveEquipmentAction: {
            youAreAboutToRemoveEquipmentFromTheRoute: 'Du er i ferd med å fjerne følgende utstyr fra ruten.',
            youAreAboutToRemoveWaypointFromTheRoute: 'Du er i ferd med å fjerne det valgte veipunktet fra ruten.',
            youWillNotLoseAnyHistoricalData:
              'Du mister ingen historiske data, men denne {{type}} vil bli fjernet fra ruten og vil ikke være tilgjengelig for fremtidige kjøringer.',
            equipment: 'utstyr',
            waypoint: 'veipunkt',
          },
          routeEditorSwapEquipmentAction: {
            replacementEquipmentIsRequired: 'Erstatningsutstyr er påkrevd',
            swappingEquipmentExplanation: 'Bytte av utstyr bytter plasseringen av det valgte utstyret med et annet utstyr.',
            swappingEquipmentCaption: 'Dette påvirker ikke historiske data, men det vil endre rekkefølgen på utstyret på ruten.',
            selectedEquipment: 'Valgt utstyr',
            swapWith: 'Bytt med',
            selectEquipment: 'Velg utstyr',
            swapEquipment: 'Bytt utstyr',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Tildelte admin-lisenser',
            of: 'av',
            edit: 'Rediger',
          },
          essentialMode: {
            disable: 'deaktivere',
            enable: 'aktivere',
            essential: 'Grunnleggende modus:',
            enabled: 'AKTIVERT',
            disabled: 'DEAKTIVERT',
            moveCompany: 'Flytt selskapet {{company}} til grunnleggende modus',
            affectedCompanies: 'Antall berørte selskaper',
            affectedAccounts: 'Antall berørte kontoer',
            archivedLeak: 'Antall arkiverte lekkasjebegivenhetsposter',
            applyingChanges:
              'Ved å anvende disse endringene vil kontoer få rollen "Grunnleggende". Brukere vil kunne se {{quota}} siste lekkasjebegivenhetsposter på et dashbord og en mobilapp. Andre poster vil bli arkivert.',
            essentialLabel: 'Endre grunnleggende modus for datterselskaper',
            turnOffEssential: 'Slå av grunnleggende modus for selskapet {{company}}',
            byApplyingChanges:
              'Ved å anvende disse endringene vil alle kontoer få rollen "Bruker". Brukere vil kunne se alle sine poster. Alle poster vil bli avarkivert.',
          },
          hubspotSync: {
            disable: 'deaktivere',
            enable: 'aktivere',
            hubspot: 'HubSpot-synkronisering',
            enabled: 'AKTIVERT',
            disabled: 'DEAKTIVERT',
            enableHubspotSync: 'Aktiver HubSpot-synkronisering for selskap {{company}}',
            disableHubspotSync: 'Deaktiver HubSpot-synkronisering for selskap {{company}}',
            affectedCompanies: 'Antall berørte selskaper',
            applyingChanges: 'Ved å bruke disse endringene, vil HubSpot-synkroniseringen være {{status}}.',
            hubspotLabel: 'Endre HubSpot-synkronisering for underselskaper',
          },
          index: 'Innstillinger',
        },
        index: {
          loadCompanyError: 'Kan ikke laste inn bedriftinformasjon. Vennligst prøv igjen senere',
          companySlug: 'Bedriftalias',
          notSelected: 'Ikke valgt',
          country: 'Land',
          accounts: 'Kontoer',
          currency: 'Valuta',
          adminLicenses: 'Administratorlisenser',
          totalCustomer: 'Totalt antall kundekontoer i treet',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Registrer enheten din',
          toolTip: 'Vennligst finn serienummeret på ditt registreringskort eller på esken.',
          serialNumberIsRequired: 'Serienummer er påkrevd',
          oops: 'Oi!',
          deviceNotFound: 'Enhets serienummer ikke funnet',
          deviceFoundNdAssociatedCompany: 'Din enhet ble funnet og er tilknyttet et selskap.',
          deviceFound: 'Din enhet ble funnet.',
          goToLogin: 'Gå til innlogging',
          contactSupport: 'Vennligst kontakt support',
        },
        deviceErrorCodes: {
          'B13:001': 'Enhets serienummer ikke funnet',
          'B13:002': 'Enheten er allerede aktivert',
          'B13:003': 'Admin-e-post ikke sendt',
          'B13:004': 'Bedrift ikke funnet',
          'B13:005': 'Bedriftet er ugyldig',
          'B13:006': 'Ugyldig type',
        },
        registerAccountView: {
          registerAccount: 'Opprett en konto',
          termsMustBeAccepted: 'Vilkårene må aksepteres.',
          saleType012Success: 'Du kan logge inn for å begynne å bruke enheten din.',
          saleType3Success: 'Du har blitt lagt til som grunnleggende bruker. Dine bedriftadministratorer har blitt varslet.',
          saleType4Success: {
            companyPlatformFound: 'Bedriftsplattformlisens funnet.',
            dialogOne: 'Denne enheten er knyttet til en ny bedriftsplattformlisens.',
            dialogTwo: 'Din bedrift kan allerede eksistere i vårt system.',
            dialogThree: 'Hvis ja, vennligst kontakt din administrator.',
          },
          termsAndConditions: {
            dialogOne: "Jeg godtar Prosaris'",
            dialogTwo: 'Tjenestevilkår',
            dialogThree:
              "og bekrefter at jeg har fått nok tid til å lese dem og forstå at de inneholder viktige vilkår om min bruk av Prosaris-programvare, som å begrense Prosaris' ansvar og hvordan mine data vil bli håndtert.",
          },
          acceptTerms: 'Godta vilkår',
          registerAccountErrorCodes: {
            'B02:001': 'Ugyldig registreringskode',
            'B02:002': 'Ugyldig brukerbedrift',
            'B02:003': 'Ugyldig brukerrolle',
            'B02:004': 'E-posten er allerede tatt',
            'B02:005': 'E-posten er allerede tatt',
            'B02:006': 'Kan ikke tilbakestille passord for din konto',
            'B02:007': 'Ugyldig brukerbedrift',
            'B02:008': 'Profilbilde mangler',
            'B02:009': 'Tilbakestilling av konto er nødvendig. Prosessen er startet',
            'B02:010': 'Kontoen har blitt deaktivert',
            'B02:011': 'Ugyldig format på profilbilde',
            'B02:012': 'Enheten er allerede registrert til et selskap',
            'B02:013': 'Serienummeret er udefinert',
            'B02:014': 'Ugyldig type',
            'B02:015': 'Lenke for tilbakestilling av passord har utløpt',
            'B02:016': 'Kan ikke opprette en konto for dette selskapet',
            'B02:017': 'Ugyldig rolle',
            'B02:018': 'Ugyldig rolle',
            'B02:019': 'Ugyldig brukerbedrift',
            'B02:020': 'Ugyldig brukerrolle',
            'B02:021': 'Brukeren tilhører ikke dette selskapet',
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Serienumre er påkrevd',
          failedToCopy: 'Mislykkede serienumre kopiert til utklippstavlen',
          invalidFormat: 'Ett eller flere enheter har ugyldig format. Vennligst sjekk og prøv igjen.',
          devices: 'Registrer enhetene dine',
          deviceSerialNumber: 'Serienummer på enheten(e)',
          note: 'Skriv inn hvert serienummer på en ny linje eller adskilt med komma.',
          title: 'Finn serienummeret på registreringskortet ditt eller esken.',
          register: 'Registrer',
          deviceRegistrationStatus: 'Status for enhetsregistrering',
          success: 'Vellykket',
          noDeviceFound: 'Ingen enheter funnet.',
          failed: 'Mislyktes',
          copyFailed: 'Kopier mislykkede serienumre til utklippstavlen',
        },
      },
      errors: {
        notFoundView: {
          title: 'Siden du leter etter er ikke her',
          description: 'Enten prøvde du en tvilsom rute, eller så kom du hit ved en feil. Uansett, prøv å bruke navigasjonen.',
        },
        accountNotFound: {
          title: 'Konto ikke funnet',
          description:
            'Vennligst prøv igjen! Hvis problemet vedvarer, er enten kontoen din for øyeblikket inaktiv, eller den er ikke blitt initialisert av administratoren. Vennligst kontakt din administrator.',
          logout: 'Logg ut',
        },
      },
      integration: {
        index: {
          loggingInError: 'Feil ved pålogging til Prosaris.',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'Ingen bilder tilgjengelig',
          activeActionRecord: {
            updateAssignmentSuccess: 'Tildelt tiltak er oppdatert.',
            updateAssignmentError: 'Feil ved oppdatering av tildelt tiltak.',
            deleteImageSuccess: 'Tildelt bilde er slettet.',
            deleteImageError: 'Feil ved sletting av tildelt tiltaksbilde.',
            confirmMarkAction: 'Vil du virkelig merke dette tiltaket som fullført?',
            confirmDeleteImage: 'Vil du virkelig slette dette bildet?',
          },
          index: 'Feil ved lasting av tildelte tiltak for en post.',
        },
        audio: {
          noAudioAvailable: 'Ingen lyddata tilgjengelig',
          errorDownloadingAudio: 'Feil ved nedlasting av lyd. Prøv igjen senere.',
          playPause: 'Spill / Pause',
          generateRegion: 'Generer Region',
          removeLastRegion: 'Fjern Siste Region',
          generateMarker: 'Generer Markør',
          removeLastMarker: 'Fjern Siste Markør',
          toggleTimeline: 'Veksle Tidslinje',
          download: 'Last Ned',
        },
        audit: {
          updateAuditSuccess: 'Revisjon oppdatert',
          updateAuditError: 'Feil ved lagring av kommentar. Vennligst prøv igjen senere.',
          createAuditSuccess: 'Revisjon opprettet',
          createAuditError: 'Feil ved opprettelse av revisjon. Vennligst prøv igjen senere.',
          deleteAuditSuccess: 'Revisjon ble vellykket slettet',
          deleteAuditError: 'Kunne ikke slette revisjon. Vennligst prøv igjen senere.',
          noComment: 'Ingen kommentarer',
          reviewComments: 'Gjennomgå kommentarer',
          reviewedBy: 'Vurdert av:',
          auditDate: 'Revisjonsdato:',
          deleteAudit: 'Slett revisjon',
          reviewedNoComments: 'Vurdert ingen kommentarer',
          auditComments: 'Revisjonskommentarer',
          saveComment: 'Lagre kommentar',
          discardChanges: 'Forkast endringer',
        },
        changeHistory: {
          created: 'opprettet',
          updated: 'oppdatert',
          deleted: 'slettet',
          transferred: 'overført',
          synced: 'synkronisert',
          leak: 'Post',
          images: 'Bilde',
          audit: 'Revisjon',
          audio: 'Lyd',
          history: 'Historikk',
          noHistory: 'Ingen historikk',
          bulk: 'som en bulkoperasjon',
          details: 'detaljer',
          unset: 'ikke satt',
        },
        fft: {
          fft: 'FFT (hurtig Fourier-transformasjon)',
          fftAvg: 'FFT-gjennomsnitt',
          notApplicable: 'Ikke tilgjengelig',
          chartLayers: 'Diagramlag',
          avg: 'Gjennomsnitt',
          all: 'Alle',
          playing: 'Spiller...',
          showTimeSeries: 'Vis tidsserie',
        },
        form: {
          updateLeakSuccess: 'Vellykket oppdatert.',
          updateLeakError: 'Feil ved oppdatering av lekkasje. Vennligst prøv igjen senere.',
          alertTitle: {
            partOne: 'Posten er i',
            partTwo: 'skrivebeskyttet',
            partThree: 'modus.',
          },
          alertDialog: {
            dialogOne: 'Følgende egenskaper kan imidlertid endres:',
            dialogTwo: 'Handlingsstatus',
          },
          GPSLocation: 'GPS-posisjon',
          completedOn: 'Fullført den:',
          lastSaved: 'Sist lagret:',
        },
        images: {
          removeSuccess: 'Bilde slettet.',
          removeError: 'Kan ikke slette lekkasjepostbilde. Vennligst prøv igjen senere.',
          text: 'Er du sikker på at du vil slette dette bildet?',
        },
        index: {
          title: 'Post',
          loadingError: 'Feil ved lasting av lekkasjepost. Vennligst prøv igjen.',
          unsavedMessage: "Ved å trykke på 'Ok' bekrefter du at alle ulagrede endringer vil bli slettet",
          notAnAuditor: 'Du er ikke en revisor',
          anEssential: 'Du er på en grunnleggende plan',
          showHistory: 'Vis historikk',
        },
        massFlow: {
          recalculating: 'Rekalkulerer strømning/tap i henhold til lekkasjeparameterinnstillingene...',
          warning: 'Inndataene har endret seg eller beregningen er utdatert, trykk "Rekalkuler" for å oppdatere strømning- og tapverdiene',
          calculatingError: 'Feil ved beregning av massestrøm. Vennligst prøv igjen.',
          noValue: 'Ingen verdi',
          calculatingSuccess: 'Strømning/tap rekalkulert',
          recalculate: 'Rekalkuler',
          calculatorVersion: 'Beregningsversjon:',
          noteDialog: {
            dialogOne: 'Endring av lekkasjeparameterinnstillingene vil oppdatere strømning/tap-beregningen. Sjekk',
            dialogTwo: 'Beregningsinnsikt',
            dialogThree: 'side for mer informasjon',
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'Posten er endret',
          close: 'Lukk',
          unsavedChanges: 'Denne posten har ulagrede endringer. Vurder å lagre disse endringene eller fjern dem helt.',
          discardChanges: 'Forkast endringer',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: 'Enhets-ID er påkrevd',
          dbPeakIsRequired: 'Målt topp-DB er påkrevd',
          dbMin: 'Målt topp-DB må være større enn eller lik 0',
          dbMax: 'Målt topp-DB må være mindre enn eller lik 95',
          dbError: 'Målt topp-DB må være et tall',
          imageUploadError: 'Feil ved opplasting av bilde. Vennligst prøv igjen senere.',
          createLeakSuccess: 'Registrert vellykket',
          createLeakError: 'Feil ved opprettelse av en post. Vennligst prøv igjen senere.',
          basicInfo: 'Grunnleggende informasjon',
          flowInput: 'Strøminngang',
          mainGas: 'Hovedgass/Komponent av interesse',
          selectComponent: 'Velg komponent',
          selectLeakType: 'Velg lekkasjetype',
          mightBeSomethingHere: 'ikke sikker ennå',
          energyLoss: 'Energikostnad',
          selectCompressorType: 'Velg kompressortype',
          noRoutes: 'Ingen ruter funnet',
          noEquipment: 'Ingen utstyr funnet',
          noLocations: 'Ingen plasseringer funnet',
          energyCost: 'Energikostnad',
          audioRecording: 'Lydopptak',
          leakSourceEquipment: 'Kilde',
          selectComponentType: 'Velg komponenttype',
          selectService: 'Velg tjeneste',
          locationAndUser: 'Sted og bruker',
          leakEventStatus: 'Hendelsesstatus',
          low: 'Lav',
          medium: 'Middels',
          high: 'Høy',
          open: 'Åpne',
          complete: 'Fullfør',
          reset: 'Tilbakestill',
        },
        formElectrical: {
          systemVoltages: 'Systemspenninger',
          source: 'Kilde',
        },
        images: {
          leakSourceImageCapture: 'Kilde Bildemottak',
          add: 'Legg til',
        },
        index: {
          title: 'Opprett post',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'Fra',
          to: 'Til',
          qualifier: 'Kvalifikator',
          savedFilters: 'Lagrede filtre',
          filter: 'Filter',
          selectFilter: 'Velg et filter...',
          filterName: 'Filternavn',
          filterNameRequired: 'Filternavn er påkrevd',
          filtersNotAvailable: 'Filtre ikke tilgjengelige',
          saveFilterTitle: 'Lagre filter',
          deleteSavedFilterTitle: 'Bekreft sletting av filter',
          deleteSavedFilterMessage: 'Er du sikker på at du vil slette filteret "{{savedFilterName}}"?',
          createFilterSuccess: "Filteret '{{filter}}' ble opprettet. Du finner det under Registreringsfiltre → Lagrede filtre",
          createFilterError: 'Feil oppstod ved opprettelse av filter. Prøv igjen senere.',
          deleteFilterSuccess: 'Filteret ble slettet',
          deleteFilterError: 'Feil oppstod ved sletting av filter. Prøv igjen senere.',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Tildel rekord',
          selectAUserToAssign: 'Velg en bruker som skal tildeles valgte post(er)',
          filterByName: 'Filtrer etter navn',
          filterByEmployeeName: 'Filtrer etter ansattes navn',
          unexpectedError: 'Uventet feil oppstod',
          noUserFound: 'Ingen brukere funnet',
          assigningActionForSelectedRecords: 'Tildeler handling for valgte poster...',
        },
        modalBulkEdit: {
          titleSingleRecord: 'Masseendring av {{selectedRecordsCount}} post',
          title: 'Masseendring av {{selectedRecordsCount}} poster',
          selectAField: 'Velg et felt',
          verifyAction:
            'Du er i ferd med å oppdatere poster som tilhører andre brukere. Bekreft ved å skrive inn antall valgte poster nedenfor.',
          matchCountError: 'Må samsvare med antall valgte poster ({{selectedRecordsCount}})',
          moreThanOneType: 'De valgte registrene har mer enn én type. Noen felt kan være utilgjengelige for masseendring.',
        },
        tableHead: {
          youAreSelecting: 'Du velger:',
          visibleRecords: 'synlige poster',
          andAllUnloadedRecords: 'og alle ulaste poster.',
          allRecordsWillBeLoaded: 'Alle poster vil bli lastet og valgt.',
          thisCanTakeAWhile: 'ette kan ta litt tid hvis du har mange poster.',
          allRecords: 'Alle poster, inkludert de usynlige',
          selectAllRecords: 'Velg alle poster.',
        },
        tableBody: {
          noRecordsToDisplay: 'Det er ingen poster å vise.',
        },
        toolBar: {
          assign: 'Tildel',
          onEssentialPlan: 'Du er på en essensiell plan. Vennligst kontakt support@prosaris.ca',
          bulkEdit: 'Masseendring',
          bulkEditSuccess: '{{len}} post oppdatert vellykket',
          bulkEditSuccessPlural: '{{len}} poster oppdatert vellykket',
          bulkEditError: 'Feil ved oppdatering av poster. Vennligst prøv igjen senere',
          exportPDF: 'Eksporter PDF',
          needAdminLicense: 'Du trenger en admin-lisens for å få tilgang til denne funksjonen. Vennligst kontakt support@prosaris.ca',
          create: 'Opprett',
          deleteRecordMessage: 'Er du sikker på at du vil slette valgt post?',
          deleteRecordMessagePlural: 'Er du sikker på at du vil slette de valgte postene?',
          permissionDenied: 'Tillatelse nektet!',
          notAllowedToDelete:
            'Du har valgt en post tilhørende noen andre som du ikke har tillatelse til å slette. Vennligst fjern den fra utvalget.',
          generatingPDF: 'Genererer PDF...',
          cannotGeneratePDF:
            'Dessverre kan vi ikke eksportere {{numberSelected}} poster i ett PDF-dokument akkurat nå. Vennligst velg opptil {{limitRecords}} poster.',
          cannotExportMessage: 'Kan ikke eksportere {{numberSelected}} poster',
          bulkEditLimitExceeded: 'Kan ikke redigere mer enn {{maxAllowed}} poster. Vennligst kontakt support@prosaris.ca',
        },
        index: {
          cannotLoadLeakRecord: 'Beklager, vi kan ikke laste lekkasjeposten akkurat nå',
          successfullyAssignedAllRecords: 'Alle poster tildelt med suksess',
          actionRecordAssigned: 'Handlingspost tildelt!',
          actionRecordAssignedPlural: 'Handlingsposter tildelt!',
          errorWhileAssigningLeak: 'Feil ved tildeling av lekkasje. Vennligst prøv igjen senere.',
          successfullyDeletedAllRecords: 'Alle poster slettet med suksess',
          successfullyDeletedRecord: '{{length}} lekkasjepost slettet med suksess',
          successfullyDeletedRecordPlural: '{{length}} lekkasjeposter slettet med suksess',
          errorOnRecordsDelete: 'Feil ved sletting av poster. Vennligst prøv igjen senere',
          leakPreview: 'Forhåndsvisning',
          leakRecord: 'Opptak: ',
        },
      },
      tagList: {
        resetTag: 'Tilbakestill tag',
        dataExport: {
          smartLeakTagsFormat: 'Smart Tags-format',
          smartLeakTagsDescription: 'Inneholder standardfeltene som vises på Smart Tags-siden',
          standardLeakRecordFormat: 'Standard rekordformat',
          standardLeakRecordDescription: 'Inneholder alle feltene relatert til lekkasjerekordene',
        },
        filters: { smartLeakTagFilters: 'Smart Tags-filtre' },
        index: {
          cannotLoadTags: 'Beklager, vi kan ikke laste Smart Tags akkurat nå',
          updateLeakSuccess: 'Smart Tags-tilbakestilling vellykket.',
          updateLeakError: 'Feil ved tilbakestilling av Smart Tags. Vennligst prøv igjen senere.',
          bulkEditSuccess: 'Tilbakestilt {{len}} Smart Tags',
          bulkEditSuccessPlural: 'Tilbakestilt {{len}} Smart Tags',
          bulkEditError: 'Feil ved tilbakestilling av Smart Tags. Vennligst prøv igjen senere.',
          tagsFileName: 'Smart Tags',
          leakFileName: 'Registre',
        },
        tableBody: {
          title: 'Vennligst bekreft tilbakestilling av taggen',
          alertDialog: {
            dialogOne: 'Du prøver å',
            dialogTwo: 'tilbakestille taggen',
            dialogThree: 'fra en lekkasjepost med',
            dialogFour: 'Handlingsstatus: ÅPEN',
            dialogFive: 'Vil du fortsette?',
          },
        },
        toolBar: {
          resetTags: 'Tilbakestill tagger',
          title: 'Vennligst bekreft masse tilbakestilling av taggene',
          alertDialog: {
            dialogOne: 'Ditt valg inneholder',
            dialogTwo: 'post med',
            dialogTwoPlural: 'poster med',
            dialogThree: 'Handlingsstatus: ÅPEN',
            dialogFour: 'Vil du fortsette?',
          },
          exportFormatCSV: 'Velg et eksportformat for CSV',
          exportFormatPDF: 'Velg et eksportformat for PDF',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Feil ved lasting av tildelte handlinger. Vennligst prøv igjen.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Tilgjengelige diagrammer',
            selectAChart: 'Velg et diagram...',
            chartFilters: 'Diagramfiltre',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Feil ved lasting av lekkasjeposter. Vennligst prøv igjen.',
              errorMessageTwo: 'Vennligst velg en dato som er tidligere enn sluttdatoen.',
              errorMessageThree: 'Vennligst velg en periode på mindre enn 6 måneder.',
              errorMessageFour: 'Vennligst velg en dato som er senere enn startdatoen.',
            },
            charts: 'Diagrammer',
            chartAlreadyExists: 'Dette diagrammet finnes allerede!',
          },
          filters: {
            select: 'Velg...',
            startDate: 'Startdato',
            endDate: 'Sluttdato',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Flyt etter lekkasjekategori (${unit})`,
              LOSS_BY_LEAK: (currency) => `Tap etter lekkasjekategori (${currency})`,
              TAGS_BY_LEAK: 'Merker etter lekkasjekategori',
              AVG_FLOW_BY_LEAK: (unit) => `Gjennomsnittlig flyt etter lekkasjekategori (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Tap og besparelsesmulighet etter reparasjonstid (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Flyt etter reparasjonstid (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Tap etter reparasjonstid (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Flyt fremdrift – Repareret vs Urepareret (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Tap fremdrift – Repareret vs Urepareret (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Flyt etter utstyr/komponent (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `Tap etter utstyr/komponent (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Merker etter utstyr/komponent',
            },
            datasetLabel: {
              totalLeakFlow: 'Total lekkasjestrøm funnet i',
              totalLoss: 'Totalt tap i',
              totalRecords: 'Totalt antall funnet oppføringer i',
              avgLeakFlow: 'Gjennomsnittlig lekkasjestrøm funnet i',
              totalLossIn: 'Totalt tap i',
              flowByRepairTime: 'Strøm etter reparasjonstid i',
              lossByRepairTime: 'Tap etter reparasjonstid i',
              flowByRepairProgress: 'Strøm funnet etter reparasjonsfremdrift i',
              recordsByCompletion: 'Poster etter fullføring i',
              flowByEquipment: 'Strøm funnet etter utstyr i',
              totalLossByEquipment: 'Totalt tap etter utstyr i',
              totalRecordsByEquipment: 'Totalt antall oppføringer etter utstyr i',
            },
            labels: {
              low: 'Lav',
              medium: 'Middels',
              high: 'Høy',
              equipment: 'Utstyr',
              savings: 'Besparelser',
              opportunity: 'Mulighet',
              loss: 'Tap',
              repaired: 'Reparert',
              unrepaired: 'Ikke reparert',
              LESS_15_DAYS: 'Mindre enn 15 dager',
              DAYS_16_TO_30: '15 til 30 dager',
              DAYS_31_TO_60: '31 til 60 dager',
              DAYS_61_TO_90: 'Mer enn 60 dager',
            },
          },
        },
        index: {
          assignedActions: 'Tildelte handlinger til deg',
        },
      },
      export: {
        imageUrlOne: 'Bilde-URL 1',
        imageUrlTwo: 'Bilde-URL 2',
        audioFileUrlOne: 'Lyd-URL',
        leakTag: 'Smart Tag/Arbeidsreferanse.',
        per: 'per ',
      },
    },
    languages: {
      English: 'Engelsk',
      Chinese: 'Kinesisk',
      Spanish: 'Spansk',
      French: 'Fransk',
      German: 'Tysk',
      Norwegian: 'Norsk',
      Italian: 'Italiensk',
      Turkish: 'Tyrkisk',
      Japanese: 'Japansk',
    },
  },
};
